import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/address';

export const reducer = createReducer(initialState, (builder) => {
  builder
    // -----getAllCountries
    .addCase(action.getAllCountries.getSuccess, ({ getAllCountries }, action) => {
      getAllCountries.status = 'success';
      getAllCountries.data = action.payload;
    })
    .addCase(action.getAllCountries.getFailed, ({ getAllCountries }, action) => {
      getAllCountries.status = 'failed';
      getAllCountries.error = action.payload;
    })
    .addCase(action.getAllCountries.getPending, ({ getAllCountries }, action) => {
      getAllCountries.status = 'pending';
      getAllCountries.error = null;
    })

    // -----getAllAddressesMe
    .addCase(action.getAllAddressesMe.getSuccess, ({ getAllAddressesMe }, action) => {
      getAllAddressesMe.status = 'success';
      getAllAddressesMe.data = action.payload.items;
    })
    .addCase(action.getAllAddressesMe.getFailed, ({ getAllAddressesMe }, action) => {
      getAllAddressesMe.status = 'failed';
      getAllAddressesMe.error = action.payload;
    })
    .addCase(action.getAllAddressesMe.getPending, ({ getAllAddressesMe }, action) => {
      getAllAddressesMe.status = 'pending';
      getAllAddressesMe.error = null;
    })

    // -----getAddressID
    .addCase(action.getAddressID.getSuccess, ({ getAddressID }, action) => {
      getAddressID.status = 'success';
      getAddressID.data = action.payload;
    })
    .addCase(action.getAddressID.getFailed, ({ getAddressID }, action) => {
      getAddressID.status = 'success';
      getAddressID.data = action.payload;
    })
    .addCase(action.getAddressID.getPending, ({ getAddressID }, action) => {
      getAddressID.status = 'success';
      getAddressID.data = action.payload;
    })

    // -----postAddress
    .addCase(action.postAddress.postSuccess, ({ postAddress }, action) => {
      postAddress.status = 'success';
      postAddress.data = action.payload;
    })
    .addCase(action.postAddress.postFailed, ({ postAddress }, action) => {
      postAddress.status = 'success';
      postAddress.data = action.payload;
    })
    .addCase(action.postAddress.postPending, ({ postAddress }, action) => {
      postAddress.status = 'success';
      postAddress.data = action.payload;
    })

    // -----putAddressID
    .addCase(action.putAddressID.putSuccess, ({ putAddressID }, action) => {
      putAddressID.status = 'success';
      putAddressID.data = action.payload;
    })
    .addCase(action.putAddressID.putFailed, ({ putAddressID }, action) => {
      putAddressID.status = 'success';
      putAddressID.data = action.payload;
    })
    .addCase(action.putAddressID.putPending, ({ putAddressID }, action) => {
      putAddressID.status = 'success';
      putAddressID.data = action.payload;
    })

    // -----deleteAddressID
    .addCase(action.deleteAddressID.deleteSuccess, ({ deleteAddressID }, action) => {
      deleteAddressID.status = 'success';
      deleteAddressID.data = action.payload;
    })
    .addCase(action.deleteAddressID.deleteFailed, ({ deleteAddressID }, action) => {
      deleteAddressID.status = 'success';
      deleteAddressID.data = action.payload;
    })
    .addCase(action.deleteAddressID.deletePending, ({ deleteAddressID }, action) => {
      deleteAddressID.status = 'success';
      deleteAddressID.data = action.payload;
    });
});
