import * as util from 'utils';
import * as variables from 'variables';

export const billingApi = {
  getBillingHistory: (param: string) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/billings/index${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getBillingStatus: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/billings/statuses`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
