export const initialState = {
  mainNav: {
    isOpen: false,
  },

  mainSpinner: {
    isShow: true,
  },

  subdomain: null,
  token: null,
};
