export const initialState: any = {
  getBillingHistory: {
    data: [],
    links: null,
    meta: null,
    status: null,
    error: null,
  },

  getBillingStatus: {
    data: [],
    status: null,
    error: null,
  },
};
