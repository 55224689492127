import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request';

export const reducer = createReducer(initialState, (builder) => {
  builder
    //-----getDataMe
    .addCase(action.user.getDataMe.getSuccess, ({ getDataMe }, action) => {
      getDataMe.status = 'success';
      getDataMe.data = action.payload;
    })
    .addCase(action.user.getDataMe.getFailed, ({ getDataMe }, action) => {
      getDataMe.status = 'failed';
      getDataMe.error = action.payload;
    })
    .addCase(action.user.getDataMe.getPending, ({ getDataMe }, action) => {
      getDataMe.status = 'pending';
      getDataMe.error = null;
    });

  builder
    //-----getDataUser
    .addCase(action.user.getDataUser.getSuccess, ({ getDataUser }, action) => {
      getDataUser.status = 'success';
      getDataUser.data = action.payload;
    })
    .addCase(action.user.getDataUser.getFailed, ({ getDataUser }, action) => {
      getDataUser.status = 'failed';
      getDataUser.error = action.payload;
    })
    .addCase(action.user.getDataUser.getPending, ({ getDataUser }, action) => {
      getDataUser.status = 'pending';
      getDataUser.error = null;
    });

  builder
    //-----getUserPhoneCodes
    .addCase(action.user.getUserPhoneCodes.getSuccess, ({ getUserPhoneCodes }, action) => {
      getUserPhoneCodes.status = 'success';
      getUserPhoneCodes.data = action.payload;
    })
    .addCase(action.user.getUserPhoneCodes.getFailed, ({ getUserPhoneCodes }, action) => {
      getUserPhoneCodes.status = 'failed';
      getUserPhoneCodes.error = action.payload;
    })
    .addCase(action.user.getUserPhoneCodes.getPending, ({ getUserPhoneCodes }, action) => {
      getUserPhoneCodes.status = 'pending';
      getUserPhoneCodes.error = null;
    });

  builder
    //-----getUserSocialGoogle
    .addCase(action.user.getUserSocialGoogle.getSuccess, ({ getUserSocialGoogle }, action) => {
      getUserSocialGoogle.status = 'success';
      getUserSocialGoogle.data = action.payload.url;
    })
    .addCase(action.user.getUserSocialGoogle.getFailed, ({ getUserSocialGoogle }, action) => {
      getUserSocialGoogle.status = 'failed';
      getUserSocialGoogle.error = action.payload;
    })
    .addCase(action.user.getUserSocialGoogle.getPending, ({ getUserSocialGoogle }, action) => {
      getUserSocialGoogle.status = 'pending';
      getUserSocialGoogle.error = null;
    });

  builder
    //-----getUserSocialFacebook
    .addCase(action.user.getUserSocialFacebook.getSuccess, ({ getUserSocialFacebook }, action) => {
      getUserSocialFacebook.status = 'success';
      getUserSocialFacebook.data = action.payload.url;
    })
    .addCase(action.user.getUserSocialFacebook.getFailed, ({ getUserSocialFacebook }, action) => {
      getUserSocialFacebook.status = 'failed';
      getUserSocialFacebook.error = action.payload;
    })
    .addCase(action.user.getUserSocialFacebook.getPending, ({ getUserSocialFacebook }, action) => {
      getUserSocialFacebook.status = 'pending';
      getUserSocialFacebook.error = null;
    });

  builder
    //-----postAvatar
    .addCase(action.user.postAvatar.postSuccess, ({ getDataMe, postAvatar }, action) => {
      postAvatar.status = 'success';
      postAvatar.data = action.payload;
      getDataMe.data = action.payload;
    })
    .addCase(action.user.postAvatar.postFailed, ({ postAvatar }, action) => {
      postAvatar.status = 'failed';
      postAvatar.error = action.payload;
    })
    .addCase(action.user.postAvatar.postPending, ({ postAvatar }, action) => {
      postAvatar.status = 'pending';
      postAvatar.error = null;
    });

  builder
    //-----postAuthGoogle
    .addCase(action.user.postAuthGoogle.postSuccess, ({ getDataMe, postAuthGoogle }, action) => {
      postAuthGoogle.status = 'success';
      postAuthGoogle.data = action.payload;
      getDataMe.status = 'success';
      getDataMe.data = action.payload.user;
    })
    .addCase(action.user.postAuthGoogle.postFailed, ({ postAuthGoogle }, action) => {
      postAuthGoogle.status = 'failed';
      postAuthGoogle.error = action.payload;
    })
    .addCase(action.user.postAuthGoogle.postPending, ({ postAuthGoogle }, action) => {
      postAuthGoogle.status = 'pending';
      postAuthGoogle.error = null;
    });

  builder
    //-----postAuthFacebook
    .addCase(
      action.user.postAuthFacebook.postSuccess,
      ({ getDataMe, postAuthFacebook }, action) => {
        postAuthFacebook.status = 'success';
        postAuthFacebook.data = action.payload;
        getDataMe.data = action.payload;
      }
    )
    .addCase(action.user.postAuthFacebook.postFailed, ({ postAuthFacebook }, action) => {
      postAuthFacebook.status = 'failed';
      postAuthFacebook.error = action.payload;
    })
    .addCase(action.user.postAuthFacebook.postPending, ({ postAuthFacebook }, action) => {
      postAuthFacebook.status = 'pending';
      postAuthFacebook.error = null;
    });

  builder
    //-----postAuthApple
    .addCase(action.user.postAuthApple.postSuccess, ({ getDataMe, postAuthApple }, action) => {
      postAuthApple.status = 'success';
      postAuthApple.data = action.payload;
      getDataMe.data = action.payload;
    })
    .addCase(action.user.postAuthApple.postFailed, ({ postAuthApple }, action) => {
      postAuthApple.status = 'failed';
      postAuthApple.error = action.payload;
    })
    .addCase(action.user.postAuthApple.postPending, ({ postAuthApple }, action) => {
      postAuthApple.status = 'pending';
      postAuthApple.error = null;
    });

  builder
    //-----postUserConnectFacebook
    .addCase(
      action.user.postUserConnectFacebook.postSuccess,
      ({ postUserConnectFacebook }, action) => {
        postUserConnectFacebook.status = 'success';
        postUserConnectFacebook.data = action.payload;
      }
    )
    .addCase(
      action.user.postUserConnectFacebook.postFailed,
      ({ postUserConnectFacebook }, action) => {
        postUserConnectFacebook.status = 'failed';
        postUserConnectFacebook.error = action.payload;
      }
    )
    .addCase(
      action.user.postUserConnectFacebook.postPending,
      ({ postUserConnectFacebook }, action) => {
        postUserConnectFacebook.status = 'pending';
        postUserConnectFacebook.error = null;
      }
    );

  builder
    //-----postConnectPaypal
    .addCase(action.user.postConnectPaypal.postSuccess, ({ postConnectPaypal }, action) => {
      postConnectPaypal.status = 'success';
      postConnectPaypal.data = action.payload;
    })
    .addCase(action.user.postConnectPaypal.postFailed, ({ postConnectPaypal }, action) => {
      postConnectPaypal.status = 'failed';
      postConnectPaypal.error = action.payload;
    })
    .addCase(action.user.postConnectPaypal.postPending, ({ postConnectPaypal }, action) => {
      postConnectPaypal.status = 'pending';
      postConnectPaypal.error = null;
    });

  builder
    //-----postConnectPaypalOld
    .addCase(action.user.postConnectPaypalOld.postSuccess, ({ postConnectPaypalOld }, action) => {
      postConnectPaypalOld.status = 'success';
      postConnectPaypalOld.data = action.payload;
    })
    .addCase(action.user.postConnectPaypalOld.postFailed, ({ postConnectPaypalOld }, action) => {
      postConnectPaypalOld.status = 'failed';
      postConnectPaypalOld.error = action.payload;
    })
    .addCase(action.user.postConnectPaypalOld.postPending, ({ postConnectPaypalOld }, action) => {
      postConnectPaypalOld.status = 'pending';
      postConnectPaypalOld.error = null;
    });

  builder
    //-----postConnectGoogleCalendar
    .addCase(
      action.user.postConnectGoogleCalendar.postSuccess,
      ({ postConnectGoogleCalendar }, action) => {
        postConnectGoogleCalendar.status = 'success';
        postConnectGoogleCalendar.data = action.payload;
      }
    )
    .addCase(
      action.user.postConnectGoogleCalendar.postFailed,
      ({ postConnectGoogleCalendar }, action) => {
        postConnectGoogleCalendar.status = 'failed';
        postConnectGoogleCalendar.error = action.payload;
      }
    )
    .addCase(
      action.user.postConnectGoogleCalendar.postPending,
      ({ postConnectGoogleCalendar }, action) => {
        postConnectGoogleCalendar.status = 'pending';
        postConnectGoogleCalendar.error = null;
      }
    );

  builder
    //-----postDisconnectPaypal
    .addCase(action.user.postDisconnectPaypal.postSuccess, ({ postDisconnectPaypal }, action) => {
      postDisconnectPaypal.status = 'success';
      postDisconnectPaypal.data = action.payload;
    })
    .addCase(action.user.postDisconnectPaypal.postFailed, ({ postDisconnectPaypal }, action) => {
      postDisconnectPaypal.status = 'failed';
      postDisconnectPaypal.error = action.payload;
    })
    .addCase(action.user.postDisconnectPaypal.postPending, ({ postDisconnectPaypal }, action) => {
      postDisconnectPaypal.status = 'pending';
      postDisconnectPaypal.error = null;
    });

  builder
    //-----postDisconnectGoogleCalendar
    .addCase(
      action.user.postDisconnectGoogleCalendar.postSuccess,
      ({ getDataMe, postDisconnectGoogleCalendar }, action) => {
        postDisconnectGoogleCalendar.status = 'success';
        postDisconnectGoogleCalendar.data = action.payload;
        getDataMe.data = action.payload.user;
      }
    )
    .addCase(
      action.user.postDisconnectGoogleCalendar.postFailed,
      ({ postDisconnectGoogleCalendar }, action) => {
        postDisconnectGoogleCalendar.status = 'failed';
        postDisconnectGoogleCalendar.error = action.payload;
      }
    )
    .addCase(
      action.user.postDisconnectGoogleCalendar.postPending,
      ({ postDisconnectGoogleCalendar }, action) => {
        postDisconnectGoogleCalendar.status = 'pending';
        postDisconnectGoogleCalendar.error = null;
      }
    );

  builder
    //-----postValidateUserName
    .addCase(action.user.postValidateUserName.postSuccess, ({ postValidateUserName }, action) => {
      postValidateUserName.status = 'success';
      postValidateUserName.data = action.payload;
    })
    .addCase(action.user.postValidateUserName.postFailed, ({ postValidateUserName }, action) => {
      postValidateUserName.status = 'failed';
      postValidateUserName.error = action.payload;
    })
    .addCase(action.user.postValidateUserName.postPending, ({ postValidateUserName }, action) => {
      postValidateUserName.status = 'pending';
      postValidateUserName.error = null;
    });

  builder
    //-----postCreateUserName
    .addCase(
      action.user.postCreateUserName.postSuccess,
      ({ getDataMe, postCreateUserName }, action) => {
        postCreateUserName.status = 'success';
        postCreateUserName.data = action.payload;
        getDataMe.data = action.payload;
      }
    )
    .addCase(action.user.postCreateUserName.postFailed, ({ postCreateUserName }, action) => {
      postCreateUserName.status = 'failed';
      postCreateUserName.error = action.payload;
    })
    .addCase(action.user.postCreateUserName.postPending, ({ postCreateUserName }, action) => {
      postCreateUserName.status = 'pending';
      postCreateUserName.error = null;
    });

  builder
    //-----postCreateUser
    .addCase(action.user.postCreateUser.postSuccess, ({ getDataMe, postCreateUser }, action) => {
      postCreateUser.status = 'success';
      postCreateUser.data = action.payload;
      getDataMe.data = action.payload.user;
    })
    .addCase(action.user.postCreateUser.postFailed, ({ postCreateUser }, action) => {
      postCreateUser.status = 'failed';
      postCreateUser.error = action.payload;
    })
    .addCase(action.user.postCreateUser.postPending, ({ postCreateUser }, action) => {
      postCreateUser.status = 'pending';
      postCreateUser.error = null;
    });

  builder
    //-----postLogIn
    .addCase(action.user.postLogIn.postSuccess, ({ getDataMe, postLogIn }, action) => {
      postLogIn.status = 'success';
      postLogIn.data = action.payload;
      getDataMe.status = 'success';
      getDataMe.data = action.payload.user;
    })
    .addCase(action.user.postLogIn.postFailed, ({ postLogIn }, action) => {
      postLogIn.status = 'failed';
      postLogIn.error = action.payload;
    })
    .addCase(action.user.postLogIn.postPending, ({ postLogIn }, action) => {
      postLogIn.status = 'pending';
      postLogIn.error = null;
    });

  builder
    //-----postForgotPassword
    .addCase(action.user.postForgotPassword.postSuccess, ({ postForgotPassword }, action) => {
      postForgotPassword.status = 'success';
      postForgotPassword.data = action.payload;
    })
    .addCase(action.user.postForgotPassword.postFailed, ({ postForgotPassword }, action) => {
      postForgotPassword.status = 'failed';
      postForgotPassword.error = action.payload;
    })
    .addCase(action.user.postForgotPassword.postPending, ({ postForgotPassword }, action) => {
      postForgotPassword.status = 'pending';
      postForgotPassword.error = null;
    });

  builder
    //-----postRecoverPassword
    .addCase(action.user.postRecoverPassword.postSuccess, ({ postRecoverPassword }, action) => {
      postRecoverPassword.status = 'success';
      postRecoverPassword.data = action.payload;
    })
    .addCase(action.user.postRecoverPassword.postFailed, ({ postRecoverPassword }, action) => {
      postRecoverPassword.status = 'failed';
      postRecoverPassword.error = action.payload;
    })
    .addCase(action.user.postRecoverPassword.postPending, ({ postRecoverPassword }, action) => {
      postRecoverPassword.status = 'pending';
      postRecoverPassword.error = null;
    });

  builder
    //-----putDataMe
    .addCase(action.user.putDataMe.putSuccess, ({ getDataMe, putDataMe }, action) => {
      putDataMe.status = 'success';
      putDataMe.status = action.payload;
      getDataMe.data = action.payload;
    })
    .addCase(action.user.putDataMe.putFailed, ({ putDataMe }, action) => {
      putDataMe.status = 'failed';
      putDataMe.error = action.payload;
    })
    .addCase(action.user.putDataMe.putPending, ({ putDataMe }, action) => {
      putDataMe.status = 'pending';
      putDataMe.error = null;
    });

  builder.addCase(action.paypal.getConnectPaypal.getSuccess, (state, action) => {
    state.getDataMe.status = 'success';
    state.getDataMe.data = action.payload;
  });

  //-----getDataMe
  //-----postFacebookSuiteDisconnect
  builder.addMatcher(
    isAnyOf(
      action.facebookBusiness.postFacebookSuiteDisconnect.postSuccess,
      action.user.getDataMe.getSuccess
    ),
    // (action) => [action.facebookBusiness.postFacebookSuiteDisconnect.postSuccess, action.user.getDataMe.getSuccess].includes(action.type),
    (state, action) => {
      state.getDataMe.status = 'success';
      state.getDataMe.data = action.payload;
    }
  );
});
