import { createAction } from '@reduxjs/toolkit';

import * as api from 'api';
import * as type from 'store/types';

export const deleteCategoryID = {
  delete: function (id: number) {
    return (dispatch: any) => {
      dispatch(this.deletePending());

      return api.categoryApi
        .deleteCategoryID(id)
        .then((response: any) => {
          if (response.status === 200) {
            return response.json().then((json: any) => dispatch(this.deleteSuccess(json)));
          } else {
            return response.json().then((json: any) => {
              throw new Error(JSON.stringify(json));
            });
          }
        })
        .catch((error: any) => {
          dispatch(this.deleteFailed(JSON.parse(error.message)));
          throw new Error();
        });
    };
  },

  deleteSuccess: createAction<any>(type.request.categoryType.DELETE_CATEGORY_ID_SUCCESS),
  deleteFailed: createAction<any>(type.request.categoryType.DELETE_CATEGORY_ID_FAILED),
  deletePending: createAction(type.request.categoryType.DELETE_CATEGORY_ID_PENDING),
};
