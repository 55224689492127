import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/billing';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.getBillingHistory.getSuccess, (state, action) => {
      state.getBillingHistory.status = 'success';
      state.getBillingHistory.data = action.payload.items;
      state.getBillingHistory.links = action.payload._links;
      state.getBillingHistory.meta = action.payload._meta;
    })
    .addCase(action.getBillingHistory.getFailed, (state, action) => {
      state.getBillingHistory.status = 'failed';
      state.getBillingHistory.error = action.payload;
    })
    .addCase(action.getBillingHistory.getPending, (state) => {
      state.getBillingHistory.status = 'pending';
      state.getBillingHistory.error = null;
    })

    .addCase(action.getBillingStatus.getSuccess, (state, action) => {
      state.getBillingStatus.status = 'success';
      state.getBillingStatus.data = action.payload.items;
    })
    .addCase(action.getBillingStatus.getFailed, (state, action) => {
      state.getBillingStatus.status = 'failed';
      state.getBillingStatus.error = action.payload;
    })
    .addCase(action.getBillingStatus.getPending, (state) => {
      state.getBillingStatus.status = 'pending';
      state.getBillingStatus.error = null;
    });
});
