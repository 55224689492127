export const state = {
  getAllItemsMe: {
    items: (state: any) => state.request.item.getAllItemsMe.data,
    seo: (state: any) => state.request.item.getAllItemsMe.seo,
    links: (state: any) => state.request.item.getAllItemsMe.links,
    meta: (state: any) => state.request.item.getAllItemsMe.meta,
    status: (state: any) => state.request.item.getAllItemsMe.status,
    error: (state: any) => state.request.item.getAllItemsMe.error,
  },

  getAllItemsUser: {
    items: (state: any) => state.request.item.getAllItemsUser.data,
    seo: (state: any) => state.request.item.getAllItemsUser.seo,
    links: (state: any) => state.request.item.getAllItemsUser.links,
    meta: (state: any) => state.request.item.getAllItemsUser.meta,
    status: (state: any) => state.request.item.getAllItemsUser.status,
    error: (state: any) => state.request.item.getAllItemsUser.error,
  },

  getItemID: {
    data: (state: any) => state.request.item.getItemID.data,
    status: (state: any) => state.request.item.getItemID.status,
    error: (state: any) => state.request.item.getItemID.error,
  },

  postItem: {
    status: (state: any) => state.request.item.postItem.status,
    error: (state: any) => state.request.item.postItem.error,
  },

  postImage: {
    status: (state: any) => state.request.item.postImage.status,
    error: (state: any) => state.request.item.postImage.error,
  },

  putItemID: {
    status: (state: any) => state.request.item.putItemID.status,
    error: (state: any) => state.request.item.putItemID.error,
  },

  deleteItemID: {
    status: (state: any) => state.request.item.deleteItemID.status,
    error: (state: any) => state.request.item.deleteItemID.error,
  },

  deleteItems: {
    status: (state: any) => state.request.item.deleteItems.status,
    error: (state: any) => state.request.item.deleteItems.error,
  },
};
