import React from 'react';

import './style.scss';

const ChatLink: React.FC = (): JSX.Element => (
  <a className='chat-link' href='https://m.me/GetPayllery' target='_blank' rel='noreferrer'>
    <i className='icon'></i>
    Chat with us!
  </a>
);

export default ChatLink;
