import React from 'react';

import './style.scss';

import SmallPopupWrapper from 'components/popups/smallPopupWrapper';

interface Props {
  visible: boolean;
  title: string;
  onClose: () => void;
}

const AlarmPopup: React.FC<Props> = ({ visible, title, onClose, children }): JSX.Element => {
  return (
    <SmallPopupWrapper visible={visible} title={title} typeTitle='v2' customClass='alarm-popup' substrateColor='violet' onClose={onClose} icon='attention'>
      {children}
    </SmallPopupWrapper>
  );
};

export default AlarmPopup;
