export const initialState: any = {
  getFacebookSuiteMe: {
    data: null,
    status: null,
    error: null,
  },

  putFacebookSuiteUpdate: {
    status: null,
    error: null,
  },

  postFacebookSuiteDisconnect: {
    status: null,
    error: null,
  },
};
