import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_app/common';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.mainNav.open, ({ mainNav }, action) => {
      mainNav.isOpen = true;
    })
    .addCase(action.mainNav.close, ({ mainNav }, action) => {
      mainNav.isOpen = false;
    });

  builder
    .addCase(action.mainSpinner.show, ({ mainSpinner }, action) => {
      mainSpinner.isShow = true;
    })
    .addCase(action.mainSpinner.hide, ({ mainSpinner }, action) => {
      mainSpinner.isShow = false;
    });

  builder.addCase(action.addSubdomain, (state, action) => {
    state.subdomain = action.payload;
  });

  builder.addCase(action.addToken, (state, action) => {
    state.token = action.payload;
  });
});
