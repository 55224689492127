import { createAction } from '@reduxjs/toolkit';

import * as api from 'api';
import * as type from 'store/types';

export const putDataMe = {
  put: function (data: {
    description: string;
    telegram: string;
    whatsapp: string;
    instagram: string;
    messenger: string;
  }) {
    return (dispatch: any) => {
      dispatch(this.putPending());

      return api.userApi
        .putDataMe(data)
        .then((response: any) => {
          if (response.status === 200) {
            return response.json().then((json: any) => dispatch(this.putSuccess(json)));
          } else {
            return response.json().then((json: any) => {
              throw new Error(JSON.stringify(json));
            });
          }
        })
        .catch((error: any) => {
          dispatch(this.putFailed(JSON.parse(error.message)));
          throw new Error();
        });
    };
  },

  putSuccess: createAction<any>(type.request.userTypes.PUT_DATA_ME_SUCCESS),
  putFailed: createAction<any>(type.request.userTypes.PUT_DATA_ME_FAILED),
  putPending: createAction(type.request.userTypes.PUT_DATA_ME_PENDING),
};
