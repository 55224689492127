import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/duration';

export const reducer = createReducer(initialState, (builder) => {
  builder
    //-----getAllDurations
    .addCase(action.getAllDurations.getSuccess, ({ getAllDurations }, action) => {
      getAllDurations.status = 'success';
      getAllDurations.data = action.payload;
    })
    .addCase(action.getAllDurations.getFailed, ({ getAllDurations }, action) => {
      getAllDurations.status = 'failed';
      getAllDurations.error = action.payload;
    })
    .addCase(action.getAllDurations.getPending, ({ getAllDurations }, action) => {
      getAllDurations.status = 'pending';
      getAllDurations.error = null;
    });
});
