import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request';

export const reducer = createReducer(initialState, (builder) => {
  builder
    //-----getConnectPaypal
    .addCase(action.paypal.getConnectPaypal.getSuccess, ({ getConnectPaypal }, action) => {
      getConnectPaypal.status = 'success';
      getConnectPaypal.data = action.payload;
    })
    .addCase(action.paypal.getConnectPaypal.getFailed, ({ getConnectPaypal }, action) => {
      getConnectPaypal.status = 'failed';
      getConnectPaypal.error = action.payload;
    })
    .addCase(action.paypal.getConnectPaypal.getPending, ({ getConnectPaypal }, action) => {
      getConnectPaypal.status = 'pending';
      getConnectPaypal.error = null;
    });

  builder
    //-----getCheckSubscribePaypal
    .addCase(
      action.paypal.getCheckSubscribePaypal.getSuccess,
      ({ getCheckSubscribePaypal }, action) => {
        getCheckSubscribePaypal.status = 'success';
        getCheckSubscribePaypal.data = action.payload;
      }
    )
    .addCase(
      action.paypal.getCheckSubscribePaypal.getFailed,
      ({ getCheckSubscribePaypal }, action) => {
        getCheckSubscribePaypal.status = 'failed';
        getCheckSubscribePaypal.error = action.payload;
      }
    )
    .addCase(
      action.paypal.getCheckSubscribePaypal.getPending,
      ({ getCheckSubscribePaypal }, action) => {
        getCheckSubscribePaypal.status = 'pending';
        getCheckSubscribePaypal.error = null;
      }
    );

  builder
    //-----getUrlPaypal
    .addCase(action.paypal.getUrlPaypal.getSuccess, ({ getUrlPaypal }, action) => {
      getUrlPaypal.status = 'success';
      getUrlPaypal.data = action.payload;
    })
    .addCase(action.paypal.getUrlPaypal.getFailed, ({ getUrlPaypal }, action) => {
      getUrlPaypal.status = 'failed';
      getUrlPaypal.error = action.payload;
    })
    .addCase(action.paypal.getUrlPaypal.getPending, ({ getUrlPaypal }, action) => {
      getUrlPaypal.status = 'pending';
      getUrlPaypal.error = null;
    });
});
