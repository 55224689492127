export const initialState: any = {
  getConnectPaypal: {
    data: null,
    status: null,
    error: null,
  },

  getCheckSubscribePaypal: {
    data: null,
    status: null,
    error: null,
  },

  getUrlPaypal: {
    data: null,
    status: null,
    error: null,
  },
};
