import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/category';

export const reducer = createReducer(initialState, (builder) => {
  builder

    //-----getAllCategoriesMe
    .addCase(action.getAllCategoriesMe.getSuccess, ({ getAllCategoriesMe }, action) => {
      getAllCategoriesMe.status = 'success';
      getAllCategoriesMe.data = action.payload.items;
    })
    .addCase(action.getAllCategoriesMe.getFailed, ({ getAllCategoriesMe }, action) => {
      getAllCategoriesMe.status = 'failed';
      getAllCategoriesMe.error = action.payload;
    })
    .addCase(action.getAllCategoriesMe.getPending, ({ getAllCategoriesMe }, action) => {
      getAllCategoriesMe.status = 'pending';
      getAllCategoriesMe.error = null;
    })

    //-----getAllCategoriesUser
    .addCase(action.getAllCategoriesUser.getSuccess, ({ getAllCategoriesUser }, action) => {
      getAllCategoriesUser.status = 'success';
      getAllCategoriesUser.data = action.payload.items;
    })
    .addCase(action.getAllCategoriesUser.getFailed, ({ getAllCategoriesUser }, action) => {
      getAllCategoriesUser.status = 'failed';
      getAllCategoriesUser.error = action.payload;
    })
    .addCase(action.getAllCategoriesUser.getPending, ({ getAllCategoriesUser }, action) => {
      getAllCategoriesUser.status = 'pending';
      getAllCategoriesUser.error = null;
    })

    //-----postCategory
    .addCase(action.postCategory.postSuccess, ({ getAllCategoriesMe, postCategory }, action) => {
      postCategory.status = 'success';
      postCategory.data = action.payload;

      getAllCategoriesMe.data = [...getAllCategoriesMe.data, action.payload];
    })
    .addCase(action.postCategory.postFailed, ({ postCategory }, action) => {
      postCategory.status = 'failed';
      postCategory.error = action.payload;
    })
    .addCase(action.postCategory.postPending, ({ postCategory }, action) => {
      postCategory.status = 'pending';
      postCategory.error = null;
    })

    //-----postCategories
    .addCase(action.postCategories.postSuccess, ({ postCategories }, action) => {
      postCategories.status = 'success';
    })
    .addCase(action.postCategories.postFailed, ({ postCategories }, action) => {
      postCategories.status = 'failed';
      postCategories.error = action.payload;
    })
    .addCase(action.postCategories.postPending, ({ postCategories }, action) => {
      postCategories.status = 'pending';
      postCategories.error = null;
    })

    //-----putCategoryID
    .addCase(action.putCategoryID.putSuccess, ({ getAllCategoriesMe, putCategoryID }, action) => {
      putCategoryID.status = 'success';

      getAllCategoriesMe.data = getAllCategoriesMe.data.map((category: any) => {
        if (category.id === action.payload.id) {
          return { ...category, name: action.payload.name };
        }
        return category;
      });
    })
    .addCase(action.putCategoryID.putFailed, ({ putCategoryID }, action) => {
      putCategoryID.status = 'failed';
      putCategoryID.error = action.payload;
    })
    .addCase(action.putCategoryID.putPending, ({ putCategoryID }, action) => {
      putCategoryID.status = 'pending';
      putCategoryID.error = null;
    })

    //-----putCategories
    .addCase(action.putCategories.putSuccess, ({ putCategories }, action) => {
      putCategories.status = 'success';
    })
    .addCase(action.putCategories.putFailed, ({ putCategories }, action) => {
      putCategories.status = 'failed';
      putCategories.error = action.payload;
    })
    .addCase(action.putCategories.putPending, ({ putCategories }, action) => {
      putCategories.status = 'pending';
      putCategories.error = null;
    })

    //-----deleteCategoryID
    .addCase(action.deleteCategoryID.deleteSuccess, ({ getAllCategoriesMe, deleteCategoryID }, action) => {
      deleteCategoryID.status = 'success';

      getAllCategoriesMe.data = getAllCategoriesMe.data.filter((category: any) => category.id !== Number(action.payload.id));
    })
    .addCase(action.deleteCategoryID.deleteFailed, ({ deleteCategoryID }, action) => {
      deleteCategoryID.status = 'failed';
      deleteCategoryID.error = action.payload;
    })
    .addCase(action.deleteCategoryID.deletePending, ({ deleteCategoryID }, action) => {
      deleteCategoryID.status = 'pending';
      deleteCategoryID.error = null;
    })

    //-----deleteCategories
    .addCase(action.deleteCategories.deleteSuccess, ({ deleteCategories }, action) => {
      deleteCategories.status = 'success';
    })
    .addCase(action.deleteCategories.deleteFailed, ({ deleteCategories }, action) => {
      deleteCategories.status = 'failed';
      deleteCategories.error = action.payload;
    })
    .addCase(action.deleteCategories.deletePending, ({ deleteCategories }, action) => {
      deleteCategories.status = 'pending';
      deleteCategories.error = null;
    });
});
