import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/plan';

export const reducer = createReducer(initialState, (builder) => {
  builder

    //-----getAllPlansMe
    .addCase(action.getAllPlansMe.getSuccess, ({ getAllPlansMe }, action) => {
      getAllPlansMe.stuts = 'success';
      getAllPlansMe.data = action.payload.items;
    })
    .addCase(action.getAllPlansMe.getFailed, ({ getAllPlansMe }, action) => {
      getAllPlansMe.stuts = 'success';
      getAllPlansMe.error = action.payload;
    })
    .addCase(action.getAllPlansMe.getPending, ({ getAllPlansMe }, action) => {
      getAllPlansMe.stuts = 'success';
      getAllPlansMe.error = null;
    });
});
