import * as variables from 'variables';

export const service = (
  subdomain: string,
  setScroll: Function,
  showMainSpinner: Function,
  hideMainSpinner: Function,
  onApproveSuccess: Function
) =>
  window.paypal_sdk
    .Buttons({
      style: {
        shape: 'pill',
        height: 44,
      },

      createOrder: (data: any, actions: any) => {
        return fetch(`https://${variables.api}.com/paypals/create-service-order/${subdomain}`, {
          method: 'POST',
          credentials: 'include',
        })
          .then((response) => {
            setScroll(true);
            return response.json();
          })
          .then((orderData) => orderData.id);
      },

      onApprove: (data: any, actions: any) => {
        showMainSpinner();

        return fetch(
          `https://${variables.api}.com/paypals/capture-service-order/${subdomain}/${data.orderID}`,
          {
            method: 'POST',
            credentials: 'include',
          }
        )
          .then(() => {
            hideMainSpinner();
            onApproveSuccess();
          })
          .catch(() => hideMainSpinner());
      },

      onCancel: function (data: any, action: any) {
        setScroll(false);
      },
    })
    .render('#paypal-button-container');
