export function throttle(callback: Function, limit: number) {
  var waiting = false;

  return function (...args: any) {
    if (!waiting) {
      callback(...args);
      waiting = true;
      setTimeout(() => (waiting = false), limit);
    }
  };
}
