import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/item';

export const reducer = createReducer(initialState, (builder) => {
  builder

    //-----getAllItemsMe
    .addCase(action.getAllItemsMe.getSuccess, ({ getAllItemsMe }, action) => {
      getAllItemsMe.status = 'success';
      getAllItemsMe.data = action.payload.items;
      getAllItemsMe.seo = action.payload.seo;
      getAllItemsMe.links = action.payload._links;
      getAllItemsMe.meta = action.payload._meta;
    })
    .addCase(action.getAllItemsMe.getFailed, ({ getAllItemsMe }, action) => {
      getAllItemsMe.status = 'failed';
      getAllItemsMe.error = action.payload;
    })
    .addCase(action.getAllItemsMe.getPending, ({ getAllItemsMe }, action) => {
      getAllItemsMe.status = 'pending';
      getAllItemsMe.error = null;
    })

    //-----getMoreItemsMe
    .addCase(action.getMoreItemsMe.getSuccess, ({ getAllItemsMe, getMoreItemsMe }, action) => {
      getMoreItemsMe.status = 'success';
      getAllItemsMe.items = [...getAllItemsMe.items, ...action.payload.items];
      getAllItemsMe.seo = action.payload.seo;
      getAllItemsMe.links = action.payload._links;
      getAllItemsMe.meta = action.payload._meta;
    })
    .addCase(action.getMoreItemsMe.getFailed, ({ getMoreItemsMe }, action) => {
      getMoreItemsMe.status = 'failed';
      getMoreItemsMe.error = action.payload;
    })
    .addCase(action.getMoreItemsMe.getPending, ({ getMoreItemsMe }, action) => {
      getMoreItemsMe.status = 'pending';
      getMoreItemsMe.error = null;
    })

    //-----getAllItemsUser
    .addCase(action.getAllItemsUser.getSuccess, ({ getAllItemsUser }, action) => {
      getAllItemsUser.status = 'success';
      getAllItemsUser.data = action.payload.items;
      getAllItemsUser.seo = action.payload.seo;
      getAllItemsUser.links = action.payload._links;
      getAllItemsUser.meta = action.payload._meta;
    })
    .addCase(action.getAllItemsUser.getFailed, ({ getAllItemsUser }, action) => {
      getAllItemsUser.status = 'failed';
      getAllItemsUser.error = action.payload;
    })
    .addCase(action.getAllItemsUser.getPending, ({ getAllItemsUser }, action) => {
      getAllItemsUser.status = 'pending';
      getAllItemsUser.error = null;
    })

    //-----getMoreItemsUser
    .addCase(action.getMoreItemsUser.getSuccess, ({ getAllItemsUser, getMoreItemsUser }, action) => {
      getMoreItemsUser.status = 'success';
      getAllItemsUser.items = [...getAllItemsUser.items, ...action.payload.items];
      getAllItemsUser.seo = action.payload.seo;
      getAllItemsUser.links = action.payload._links;
      getAllItemsUser.meta = action.payload._meta;
    })
    .addCase(action.getMoreItemsUser.getFailed, ({ getMoreItemsUser }, action) => {
      getMoreItemsUser.status = 'failed';
      getMoreItemsUser.error = action.payload;
    })
    .addCase(action.getMoreItemsUser.getPending, ({ getMoreItemsUser }, action) => {
      getMoreItemsUser.status = 'pending';
      getMoreItemsUser.error = null;
    })

    //-----getItemID
    .addCase(action.getItemID.getSuccess, ({ getItemID }, action) => {
      getItemID.status = 'success';
      getItemID.data = action.payload;
    })
    .addCase(action.getItemID.getFailed, ({ getItemID }, action) => {
      getItemID.status = 'failed';
      getItemID.error = action.payload;
    })
    .addCase(action.getItemID.getPending, ({ getItemID }, action) => {
      getItemID.status = 'pending';
      getItemID.error = null;
    })

    //-----postItem
    .addCase(action.postItem.postSuccess, ({ postItem }, action) => {
      postItem.status = 'success';
    })
    .addCase(action.postItem.postFailed, ({ postItem }, action) => {
      postItem.status = 'failed';
      postItem.error = action.payload;
    })
    .addCase(action.postItem.postPending, ({ postItem }, action) => {
      postItem.status = 'pending';
      postItem.error = null;
    })

    //-----postImage
    .addCase(action.postImage.postSuccess, ({ postImage }, action) => {
      postImage.status = 'success';
    })
    .addCase(action.postImage.postFailed, ({ postImage }, action) => {
      postImage.status = 'failed';
      postImage.error = action.payload;
    })
    .addCase(action.postImage.postPending, ({ postImage }, action) => {
      postImage.status = 'pending';
      postImage.error = null;
    })

    //-----putItemID
    .addCase(action.putItemID.putSuccess, ({ getItemID, putItemID }, action) => {
      putItemID.status = 'success';
      getItemID.data = action.payload;
    })
    .addCase(action.putItemID.putFailed, ({ putItemID }, action) => {
      putItemID.status = 'failed';
      putItemID.error = action.payload;
    })
    .addCase(action.putItemID.putPending, ({ putItemID }, action) => {
      putItemID.status = 'pending';
      putItemID.error = null;
    })

    //-----deleteItemID
    .addCase(action.deleteItemID.deleteSuccess, ({ deleteItemID }, action) => {
      deleteItemID.status = 'success';
    })
    .addCase(action.deleteItemID.deleteFailed, ({ deleteItemID }, action) => {
      deleteItemID.status = 'failed';
      deleteItemID.error = action.payload;
    })
    .addCase(action.deleteItemID.deletePending, ({ deleteItemID }, action) => {
      deleteItemID.status = 'pending';
      deleteItemID.error = null;
    })

    //-----deleteItems
    .addCase(action.deleteItems.deleteSuccess, ({ deleteItems }, action) => {
      deleteItems.status = 'success';
    })
    .addCase(action.deleteItems.deleteFailed, ({ deleteItems }, action) => {
      deleteItems.status = 'failed';
      deleteItems.error = action.payload;
    })
    .addCase(action.deleteItems.deletePending, ({ deleteItems }, action) => {
      deleteItems.status = 'pending';
      deleteItems.error = null;
    });
});
