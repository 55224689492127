export const state = {
  getDataMe: {
    data: (state: any) => state.request.user.getDataMe.data,
    status: (state: any) => state.request.user.getDataMe.status,
    error: (state: any) => state.request.user.getDataMe.error,
  },

  getDataUser: {
    data: (state: any) => state.request.user.getDataUser.data,
    status: (state: any) => state.request.user.getDataUser.status,
    error: (state: any) => state.request.user.getDataUser.error,
  },

  getUserPhoneCodes: {
    data: (state: any) => state.request.user.getUserPhoneCodes.data,
    status: (state: any) => state.request.user.getUserPhoneCodes.status,
    error: (state: any) => state.request.user.getUserPhoneCodes.error,
  },

  getUserSocialGoogle: {
    data: (state: any) => state.request.user.getUserSocialGoogle.data,
    status: (state: any) => state.request.user.getUserSocialGoogle.status,
    error: (state: any) => state.request.user.getUserSocialGoogle.error,
  },

  getUserSocialFacebook: {
    data: (state: any) => state.request.user.getUserSocialFacebook.data,
    status: (state: any) => state.request.user.getUserSocialFacebook.status,
    error: (state: any) => state.request.user.getUserSocialFacebook.error,
  },

  postAvatar: {
    data: (state: any) => state.request.user.postAvatar.data,
    status: (state: any) => state.request.user.postAvatar.status,
    error: (state: any) => state.request.user.postAvatar.error,
  },

  postAuthGoogle: {
    data: (state: any) => state.request.user.postAuthGoogle.data,
    status: (state: any) => state.request.user.postAuthGoogle.status,
    error: (state: any) => state.request.user.postAuthGoogle.error,
  },

  postAuthFacebook: {
    data: (state: any) => state.request.user.postAuthFacebook.data,
    status: (state: any) => state.request.user.postAuthFacebook.status,
    error: (state: any) => state.request.user.postAuthFacebook.error,
  },

  postAuthApple: {
    data: (state: any) => state.request.user.postAuthApple.data,
    status: (state: any) => state.request.user.postAuthApple.status,
    error: (state: any) => state.request.user.postAuthApple.error,
  },

  postUserConnectFacebook: {
    data: (state: any) => state.request.user.postUserConnectFacebook.data,
    status: (state: any) => state.request.user.postUserConnectFacebook.status,
    error: (state: any) => state.request.user.postUserConnectFacebook.error,
  },

  postConnectPaypal: {
    data: (state: any) => state.request.user.postConnectPaypal.data,
    status: (state: any) => state.request.user.postConnectPaypal.status,
    error: (state: any) => state.request.user.postConnectPaypal.error,
  },

  postConnectPaypalOld: {
    data: (state: any) => state.request.user.postConnectPaypalOld.data,
    status: (state: any) => state.request.user.postConnectPaypalOld.status,
    error: (state: any) => state.request.user.postConnectPaypalOld.error,
  },

  postConnectGoogleCalendar: {
    data: (state: any) => state.request.user.postConnectGoogleCalendar.data,
    status: (state: any) => state.request.user.postConnectGoogleCalendar.status,
    error: (state: any) => state.request.user.postConnectGoogleCalendar.error,
  },

  postDisconnectPaypal: {
    data: (state: any) => state.request.user.postDisconnectPaypal.data,
    status: (state: any) => state.request.user.postDisconnectPaypal.status,
    error: (state: any) => state.request.user.postDisconnectPaypal.error,
  },

  postDisconnectGoogleCalendar: {
    data: (state: any) => state.request.user.postDisconnectGoogleCalendar.data,
    status: (state: any) => state.request.user.postDisconnectGoogleCalendar.status,
    error: (state: any) => state.request.user.postDisconnectGoogleCalendar.error,
  },

  postValidateUserName: {
    data: (state: any) => state.request.user.postValidateUserName.data,
    status: (state: any) => state.request.user.postValidateUserName.status,
    error: (state: any) => state.request.user.postValidateUserName.error,
  },

  postCreateUserName: {
    data: (state: any) => state.request.user.postCreateUserName.data,
    status: (state: any) => state.request.user.postCreateUserName.status,
    error: (state: any) => state.request.user.postCreateUserName.error,
  },

  postCreateUser: {
    data: (state: any) => state.request.user.postCreateUser.data,
    status: (state: any) => state.request.user.postCreateUser.status,
    error: (state: any) => state.request.user.postCreateUser.error,
  },

  postLogIn: {
    data: (state: any) => state.request.user.postLogIn.data,
    status: (state: any) => state.request.user.postLogIn.status,
    error: (state: any) => state.request.user.postLogIn.error,
  },

  postForgotPassword: {
    data: (state: any) => state.request.user.postForgotPassword.data,
    status: (state: any) => state.request.user.postForgotPassword.status,
    error: (state: any) => state.request.user.postForgotPassword.error,
  },

  postRecoverPassword: {
    data: (state: any) => state.request.user.postRecoverPassword.data,
    status: (state: any) => state.request.user.postRecoverPassword.status,
    error: (state: any) => state.request.user.postRecoverPassword.error,
  },

  putDataMe: {
    data: (state: any) => state.request.user.putDataMe.data,
    status: (state: any) => state.request.user.putDataMe.status,
    error: (state: any) => state.request.user.putDataMe.error,
  },
};
