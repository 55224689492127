import { getAddressID } from 'store/actions/_request/address';

export const addressType = {
  GET_ALL_COUNTRIES_SUCCESS: 'GET_ALL_COUNTRIES_SUCCESS',
  GET_ALL_COUNTRIES_FAILED: 'GET_ALL_COUNTRIES_FAILED',
  GET_ALL_COUNTRIES_PENDING: 'GET_ALL_COUNTRIES_PENDING',

  GET_ALL_ADDRESSES_ME_SUCCESS: 'GET_ALL_ADDRESSES_ME_SUCCESS',
  GET_ALL_ADDRESSES_ME_FAILED: 'GET_ALL_ADDRESSES_ME_FAILED',
  GET_ALL_ADDRESSES_ME_PENDING: 'GET_ALL_ADDRESSES_ME_PENDING',

  getAddressID: 'request/getAddressID',
  GET_ADDRESS_ID_SUCCESS: 'GET_ADDRESS_ID_SUCCESS',
  GET_ADDRESS_ID_FAILED: 'GET_ADDRESS_ID_FAILED',
  GET_ADDRESS_ID_PENDING: 'GET_ADDRESS_ID_PENDING',

  POST_ADDRESS_SUCCESS: 'POST_ADDRESS_SUCCESS',
  POST_ADDRESS_FAILED: 'POST_ADDRESS_FAILED',
  POST_ADDRESS_PENDING: 'POST_ADDRESS_PENDING',

  PUT_ADDRESS_ID_SUCCESS: 'PUT_ADDRESS_ID_SUCCESS',
  PUT_ADDRESS_ID_FAILED: 'PUT_ADDRESS_ID_FAILED',
  PUT_ADDRESS_ID_PENDING: 'PUT_ADDRESS_ID_PENDING',

  DELETE_ADDRESS_ID_SUCCESS: 'DELETE_ADDRESS_ID_SUCCESS',
  DELETE_ADDRESS_ID_FAILED: 'DELETE_ADDRESS_ID_FAILED',
  DELETE_ADDRESS_ID_PENDING: 'DELETE_ADDRESS_ID_PENDING',
};
