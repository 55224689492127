import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/order';

export const reducer = createReducer(initialState, (builder) => {
  builder

    //-----getAllOrdersMe
    .addCase(action.getAllOrdersMe.getSuccess, ({ getAllOrdersMe }, action) => {
      getAllOrdersMe.status = 'success';
      getAllOrdersMe.data = action.payload;
    })
    .addCase(action.getAllOrdersMe.getFailed, ({ getAllOrdersMe }, action) => {
      getAllOrdersMe.status = 'failed';
      getAllOrdersMe.error = action.payload;
    })
    .addCase(action.getAllOrdersMe.getPending, ({ getAllOrdersMe }, action) => {
      getAllOrdersMe.status = 'pending';
      getAllOrdersMe.error = null;
    })

    //-----getOrderID
    .addCase(action.getOrderID.getSuccess, ({ getOrderID }, action) => {
      getOrderID.status = 'success';
      getOrderID.data = action.payload;
    })
    .addCase(action.getOrderID.getFailed, ({ getOrderID }, action) => {
      getOrderID.status = 'failed';
      getOrderID.error = action.payload;
    })
    .addCase(action.getOrderID.getPending, ({ getOrderID }, action) => {
      getOrderID.status = 'pending';
      getOrderID.error = null;
    })

    //-----getOrderTypes
    .addCase(action.getOrderTypes.getSuccess, ({ getOrderTypes }, action) => {
      getOrderTypes.status = 'success';
      getOrderTypes.data = action.payload;
    })
    .addCase(action.getOrderTypes.getFailed, ({ getOrderTypes }, action) => {
      getOrderTypes.status = 'failed';
      getOrderTypes.error = action.payload;
    })
    .addCase(action.getOrderTypes.getPending, ({ getOrderTypes }, action) => {
      getOrderTypes.status = 'pending';
      getOrderTypes.error = null;
    });
});
