import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/cart';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.getAllItemsForCart.getSuccess, ({ getAllItemsForCart }, action) => {
      getAllItemsForCart.status = 'success';
      getAllItemsForCart.data = action.payload;
    })
    .addCase(action.getAllItemsForCart.getFailed, ({ getAllItemsForCart }, action) => {
      getAllItemsForCart.status = 'failed';
      getAllItemsForCart.error = action.payload;
    })
    .addCase(action.getAllItemsForCart.getPending, ({ getAllItemsForCart }) => {
      getAllItemsForCart.status = 'pending';
      getAllItemsForCart.error = null;
    });

  builder
    .addCase(action.getAllItemsSuccessfulForCart.getSuccess, ({ getAllItemsSuccessfulForCart }, action) => {
      getAllItemsSuccessfulForCart.status = 'success';
      getAllItemsSuccessfulForCart.data = action.payload;
    })
    .addCase(action.getAllItemsSuccessfulForCart.getFailed, ({ getAllItemsSuccessfulForCart }, action) => {
      getAllItemsSuccessfulForCart.status = 'failed';
      getAllItemsSuccessfulForCart.error = action.payload;
    })
    .addCase(action.getAllItemsSuccessfulForCart.getPending, ({ getAllItemsSuccessfulForCart }) => {
      getAllItemsSuccessfulForCart.status = 'pending';
      getAllItemsSuccessfulForCart.error = null;
    });

  builder
    .addCase(action.addProductToCart.postSuccess, ({ getAllItemsForCart, addProductToCart }, action) => {
      addProductToCart.status = 'success';

      getAllItemsForCart.status = 'success';
      getAllItemsForCart.data = action.payload;
    })
    .addCase(action.addProductToCart.postFailed, ({ addProductToCart }, action) => {
      addProductToCart.status = 'failed';
      addProductToCart.error = action.payload;
    })
    .addCase(action.addProductToCart.postPending, ({ addProductToCart }) => {
      addProductToCart.status = 'pending';
      addProductToCart.error = null;
    });

  builder
    .addCase(action.addServiceToCart.postSuccess, ({ addServiceToCart }, action) => {
      addServiceToCart.status = 'success';
      addServiceToCart.data = action.payload;
    })
    .addCase(action.addServiceToCart.postFailed, ({ addServiceToCart }, action) => {
      addServiceToCart.status = 'failed';
      addServiceToCart.error = action.payload;
    })
    .addCase(action.addServiceToCart.postPending, ({ addServiceToCart }) => {
      addServiceToCart.status = 'pending';
      addServiceToCart.error = null;
    });

  builder
    .addCase(action.addProductsToCart.postSuccess, ({ getAllItemsForCart, addProductsToCart }, action) => {
      addProductsToCart.status = 'success';

      getAllItemsForCart.status = 'success';
      getAllItemsForCart.data = action.payload;
    })
    .addCase(action.addProductsToCart.postFailed, ({ addProductsToCart }, action) => {
      addProductsToCart.status = 'failed';
      addProductsToCart.error = action.payload;
    })
    .addCase(action.addProductsToCart.postPending, ({ addProductsToCart }) => {
      addProductsToCart.status = 'pending';
      addProductsToCart.error = null;
    });

  builder
    .addCase(action.changeQuantityForProductInCart.putSuccess, ({ getAllItemsForCart, changeQuantityForProductInCart }, action) => {
      changeQuantityForProductInCart.status = 'success';

      getAllItemsForCart.status = 'success';
      getAllItemsForCart.data = action.payload;
    })
    .addCase(action.changeQuantityForProductInCart.putFailed, ({ changeQuantityForProductInCart }, action) => {
      changeQuantityForProductInCart.status = 'failed';
      changeQuantityForProductInCart.error = action.payload;
    })
    .addCase(action.changeQuantityForProductInCart.putPending, ({ changeQuantityForProductInCart }) => {
      changeQuantityForProductInCart.status = 'pending';
      changeQuantityForProductInCart.error = null;
    });

  builder
    .addCase(action.changeQuantityForProductsInCart.putSuccess, ({ getAllItemsForCart, changeQuantityForProductsInCart }, action) => {
      changeQuantityForProductsInCart.status = 'success';

      getAllItemsForCart.status = 'success';
      getAllItemsForCart.data = action.payload;
    })
    .addCase(action.changeQuantityForProductsInCart.putFailed, ({ changeQuantityForProductsInCart }, action) => {
      changeQuantityForProductsInCart.status = 'failed';
      changeQuantityForProductsInCart.error = action.payload;
    })
    .addCase(action.changeQuantityForProductsInCart.putPending, ({ changeQuantityForProductsInCart }) => {
      changeQuantityForProductsInCart.status = 'pending';
      changeQuantityForProductsInCart.error = null;
    });

  builder
    .addCase(action.changeDeliveryForProductsInCart.putSuccess, ({ getAllItemsForCart, changeDeliveryForProductsInCart }, action) => {
      changeDeliveryForProductsInCart.status = 'success';

      getAllItemsForCart.status = 'success';
      getAllItemsForCart.data = action.payload;
    })
    .addCase(action.changeDeliveryForProductsInCart.putFailed, ({ changeDeliveryForProductsInCart }, action) => {
      changeDeliveryForProductsInCart.status = 'failed';
      changeDeliveryForProductsInCart.error = action.payload;
    })
    .addCase(action.changeDeliveryForProductsInCart.putPending, ({ changeDeliveryForProductsInCart }) => {
      changeDeliveryForProductsInCart.status = 'pending';
      changeDeliveryForProductsInCart.error = null;
    });

  builder
    .addCase(action.changeAddressForProductsInCart.putSuccess, ({ getAllItemsForCart, changeAddressForProductsInCart }, action) => {
      changeAddressForProductsInCart.status = 'success';

      getAllItemsForCart.status = 'success';
      getAllItemsForCart.data = action.payload;
    })
    .addCase(action.changeAddressForProductsInCart.putFailed, ({ changeAddressForProductsInCart }, action) => {
      changeAddressForProductsInCart.status = 'failed';
      changeAddressForProductsInCart.error = action.payload;
    })
    .addCase(action.changeAddressForProductsInCart.putPending, ({ changeAddressForProductsInCart }) => {
      changeAddressForProductsInCart.status = 'pending';
      changeAddressForProductsInCart.error = null;
    });

  builder
    .addCase(action.deleteItemFromCart.deleteSuccess, ({ getAllItemsForCart, deleteItemFromCart }, action) => {
      deleteItemFromCart.status = 'success';

      getAllItemsForCart.status = 'success';
      getAllItemsForCart.data = action.payload;
    })
    .addCase(action.deleteItemFromCart.deleteFailed, ({ deleteItemFromCart }, action) => {
      deleteItemFromCart.status = 'failed';
      deleteItemFromCart.error = action.payload;
    })
    .addCase(action.deleteItemFromCart.deletePending, ({ deleteItemFromCart }) => {
      deleteItemFromCart.status = 'pending';
      deleteItemFromCart.error = null;
    });
});
