import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/location';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.getAllLocationsMe.getSuccess, ({ getAllLocationsMe }, action) => {
      getAllLocationsMe.status = 'success';
      getAllLocationsMe.data = action.payload;
    })
    .addCase(action.getAllLocationsMe.getFailed, ({ getAllLocationsMe }, action) => {
      getAllLocationsMe.status = 'failed';
      getAllLocationsMe.error = action.payload;
    })
    .addCase(action.getAllLocationsMe.getPending, ({ getAllLocationsMe }, action) => {
      getAllLocationsMe.status = 'pending';
      getAllLocationsMe.error = null;
    });
});
