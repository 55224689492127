// import { useEffect, useRef } from 'react';
import React from 'react';
import { createPortal } from 'react-dom';

const Portal: React.FC = ({ children }): JSX.Element => {
  const popups: any = document.getElementById('payllery');

  return createPortal(children, popups);
};

export default Portal;
