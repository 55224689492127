export const state = {
  getAllCountries: {
    data: (state: any) => state.request.address.getAllCountries.data,
    status: (state: any) => state.request.address.getAllCountries.data,
    error: (state: any) => state.request.address.getAllCountries.data,
  },

  getAllAddressesMe: {
    data: (state: any) => state.request.address.getAllAddressesMe.data,
    status: (state: any) => state.request.address.getAllAddressesMe.data,
    error: (state: any) => state.request.address.getAllAddressesMe.data,
  },

  getAddressID: {
    data: (state: any) => state.request.address.getAllAddressesMe.data,
    status: (state: any) => state.request.address.getAllAddressesMe.data,
    error: (state: any) => state.request.address.getAllAddressesMe.data,
  },

  postAddress: {
    data: (state: any) => state.request.address.getAllAddressesMe.data,
    status: (state: any) => state.request.address.getAllAddressesMe.data,
    error: (state: any) => state.request.address.getAllAddressesMe.data,
  },

  putAddressID: {
    data: (state: any) => state.request.address.getAllAddressesMe.data,
    status: (state: any) => state.request.address.getAllAddressesMe.data,
    error: (state: any) => state.request.address.getAllAddressesMe.data,
  },

  deleteAddressID: {
    data: (state: any) => state.request.address.getAllAddressesMe.data,
    status: (state: any) => state.request.address.getAllAddressesMe.data,
    error: (state: any) => state.request.address.getAllAddressesMe.data,
  },
};
