export const initialState: any = {
  getAllItemsForCart: {
    data: null,
    // dataShort: [],
    // totalDeliveryCost: null,
    // totalDeliveryCostFormated: null,
    // totalPrice: null,
    // totalPriceFormated: null,
    // totalQuantity: null,
    status: null,
    error: null,
  },

  getAllItemsSuccessfulForCart: {
    data: null,
    status: null,
    error: null,
  },

  addProductToCart: {
    status: null,
    error: null,
  },

  addServiceToCart: {
    data: null,
    status: null,
    error: null,
  },

  addProductsToCart: {
    status: null,
    error: null,
  },

  changeQuantityForProductInCart: {
    status: null,
    error: null,
  },

  changeQuantityForProductsInCart: {
    status: null,
    error: null,
  },

  changeDeliveryForProductsInCart: {
    status: null,
    error: null,
  },

  changeAddressForProductsInCart: {
    status: null,
    error: null,
  },

  deleteItemFromCart: {
    status: null,
    error: null,
  },
};
