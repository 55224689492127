import * as util from 'utils';
import * as variables from 'variables';

export const orderApi = {
  getAllOrdersMe: (param: string) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/orders/me${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getOrderID: (id: number) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/orders/view/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getOrderTypes: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/orders/types`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
