import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/type';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.getAllTypes.getSuccess, ({ getAllTypes }, action) => {
      getAllTypes.status = 'success';
      getAllTypes.data = action.payload;
    })
    .addCase(action.getAllTypes.getFailed, ({ getAllTypes }, action) => {
      getAllTypes.status = 'failed';
      getAllTypes.error = action.payload;
    })
    .addCase(action.getAllTypes.getPending, ({ getAllTypes }, action) => {
      getAllTypes.status = 'pending';
      getAllTypes.error = null;
    });
});
