export const state = {
  getAllCategoriesMe: {
    data: (state: any) => state.request.category.getAllCategoriesMe.data,
    status: (state: any) => state.request.category.getAllCategoriesMe.status,
    error: (state: any) => state.request.category.getAllCategoriesMe.error,
  },

  getAllCategoriesUser: {
    data: (state: any) => state.request.category.getAllCategoriesUser.data,
    status: (state: any) => state.request.category.getAllCategoriesUser.status,
    error: (state: any) => state.request.category.getAllCategoriesUser.error,
  },

  postCategory: {
    data: (state: any) => state.request.category.postCategory.data,
    status: (state: any) => state.request.category.postCategory.status,
    error: (state: any) => state.request.category.postCategory.error,
  },

  postCategories: {
    status: (state: any) => state.request.category.postCategories.status,
    error: (state: any) => state.request.category.postCategories.error,
  },

  putCategoryID: {
    status: (state: any) => state.request.category.putCategoryID.status,
    error: (state: any) => state.request.category.putCategoryID.error,
  },

  putCategories: {
    status: (state: any) => state.request.category.putCategories.status,
    error: (state: any) => state.request.category.putCategories.error,
  },

  deleteCategoryID: {
    status: (state: any) => state.request.category.deleteCategoryID.status,
    error: (state: any) => state.request.category.deleteCategoryID.error,
  },

  deleteCategories: {
    status: (state: any) => state.request.category.deleteCategories.status,
    error: (state: any) => state.request.category.deleteCategories.error,
  },
};
