export const initialState: any = {
  getAllItemsMe: {
    data: [],
    seo: {},
    links: {},
    meta: {},
    status: null,
    error: null,
  },

  getMoreItemsMe: {
    status: null,
    error: null,
  },

  getAllItemsUser: {
    data: [],
    seo: {},
    links: {},
    meta: {},
    status: null,
    error: null,
  },

  getMoreItemsUser: {
    status: null,
    error: null,
  },

  getItemID: {
    data: {},
    status: null,
    error: null,
  },

  postItem: {
    status: null,
    error: null,
  },

  postImage: {
    status: null,
    error: null,
  },

  putItemID: {
    status: null,
    error: null,
  },

  deleteItemID: {
    status: null,
    error: null,
  },

  deleteItems: {
    status: null,
    error: null,
  },
};
