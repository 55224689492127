import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_app/popup';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.infoPopup.show, ({ infoPopup }, action) => {
      infoPopup.show = true;
      infoPopup.content.title = action.payload.title;
      infoPopup.content.message = action.payload.message;
    })
    .addCase(action.infoPopup.hide, ({ infoPopup }, action) => {
      infoPopup.show = false;
      infoPopup.content.title = '';
      infoPopup.content.message = '';
    });
});
