import { createAction } from '@reduxjs/toolkit';

import * as api from 'api';
import * as type from 'store/types';

export const postItem = {
  post: function (data: {}) {
    return (dispatch: any) => {
      dispatch(this.postPending());

      return api.itemApi
        .postItem(data)
        .then((response: any) => {
          if (response.status === 201) {
            return response.json().then((json: any) => dispatch(this.postSuccess(json)));
          } else {
            return response.json().then((json: any) => {
              throw new Error(JSON.stringify(json));
            });
          }
        })
        .catch((error: any) => {
          dispatch(this.postFailed(JSON.parse(error.message)));
          throw new Error();
        });
    };
  },

  postSuccess: createAction<any>(type.request.itemsType.POST_ITEM_SUCCESS),
  postFailed: createAction<any>(type.request.itemsType.POST_ITEM_FAILED),
  postPending: createAction(type.request.itemsType.POST_ITEM_PENDING),
};
