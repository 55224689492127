import React from 'react';

import './style.scss';

interface Props {
  bgColor: string;
  onEvent?: ((event: any) => void) | any;
  [propName: string]: any;
}

const IconButton: React.FC<Props> = ({ bgColor, onEvent, children, ...rest }): JSX.Element => {
  return (
    <button
      {...rest}
      className={['icon-btn', rest.className].filter(Boolean).join(' ')}
      onClick={onEvent}
    >
      <i className={`icon-bg ${bgColor}`}>{children}</i>
    </button>
  );
};

export default IconButton;
