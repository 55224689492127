import { createAction } from '@reduxjs/toolkit';

import * as api from 'api';
import * as type from 'store/types';

export const postDisconnectGoogleCalendar = {
  post: function () {
    return (dispatch: any) => {
      dispatch(this.postPending());

      return api.userApi
        .postDisconnectGoogleCalendar()
        .then((response: any) => {
          if (response.status === 200) {
            return response.json().then((json: any) => dispatch(this.postSuccess(json)));
          } else {
            return response.json().then((json: any) => {
              throw new Error(JSON.stringify(json));
            });
          }
        })
        .catch((error: any) => {
          dispatch(this.postFailed(JSON.parse(error.message)));
          throw new Error();
        });
    };
  },

  postSuccess: createAction<any>(type.request.userTypes.POST_DISCONNECT_GOOGLE_CALENDAR_SUCCESS),
  postFailed: createAction<any>(type.request.userTypes.POST_DISCONNECT_GOOGLE_CALENDAR_FAILED),
  postPending: createAction(type.request.userTypes.POST_DISCONNECT_GOOGLE_CALENDAR_PENDING),
};
