import { createAction } from '@reduxjs/toolkit';

import * as api from 'api';
import * as type from 'store/types';
import * as util from 'utils';
import * as variables from 'variables/api';

export const postAuthFacebook = {
  post: function (data: any) {
    return (dispatch: any) => {
      dispatch(this.postPending());

      return api.userApi
        .postAuthFacebook(data)
        .then((response: any) => {
          if (response.status === 200) {
            return response.json().then((json: any) => {
              util.cookie.set('token', `${json.jwt}`, { domain: `.${variables.domain}.com` });
              dispatch(this.postSuccess(json));
            });
          } else {
            return response.json().then((json: any) => {
              throw new Error(JSON.stringify(json));
            });
          }
        })
        .catch((error: any) => {
          dispatch(this.postFailed(JSON.parse(error.message)));
          throw new Error();
        });
    };
  },

  postSuccess: createAction<any>(type.request.userTypes.POST_AUTH_FACEBOOK_SUCCESS),
  postFailed: createAction<any>(type.request.userTypes.POST_AUTH_FACEBOOK_FAILED),
  postPending: createAction(type.request.userTypes.POST_AUTH_FACEBOOK_PENDING),
};
