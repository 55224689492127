export const state = {
  getAllOrdersMe: {
    data: (state: any) => state.request.order.getAllOrdersMe.data,
    status: (state: any) => state.request.order.getAllOrdersMe.status,
    error: (state: any) => state.request.order.getAllOrdersMe.error,
  },
  getOrderID: {
    data: (state: any) => state.request.order.getOrderID.data,
    status: (state: any) => state.request.order.getOrderID.status,
    error: (state: any) => state.request.order.getOrderID.error,
  },
  getOrderTypes: {
    data: (state: any) => state.request.order.getOrderTypes.data,
    status: (state: any) => state.request.order.getOrderTypes.status,
    error: (state: any) => state.request.order.getOrderTypes.error,
  },
};
