export const state = {
  getFacebookSuiteMe: {
    data: (state: any) => state.request.facebookBusiness.getFacebookSuiteMe.data,
    status: (state: any) => state.request.facebookBusiness.getFacebookSuiteMe.status,
    error: (state: any) => state.request.facebookBusiness.getFacebookSuiteMe.error,
  },

  putFacebookSuiteUpdate: {
    status: (state: any) => state.request.facebookBusiness.putFacebookSuiteUpdate.status,
    error: (state: any) => state.request.facebookBusiness.putFacebookSuiteUpdate.error,
  },

  postFacebookSuiteDisconnect: {
    status: (state: any) => state.request.facebookBusiness.postFacebookSuiteDisconnect.status,
    error: (state: any) => state.request.facebookBusiness.postFacebookSuiteDisconnect.error,
  },
};
