import * as types from 'store/types';

const initialState = {
  // type: {
  //   activeIDs: [],
  // },

  category: {
    edit: {
      editMode: false,
      editModeItem: false,
      createModeItem: false,
    },
    activeIDs: [],
  },
};

export const filterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    // case types.filterTypes.EDIT_MODE_IN_GROUP:
    //   return { ...state, group: { ...state.group, editMode: action.key } };

    // case types.filterTypes.EDIT_MODE_ITEM_IN_GROUP:
    //   return { ...state, group: { ...state.group, editModeItem: action.key } };

    // =====
    // case types.typeTypes.GET_TYPES_SUCCESS:
    //   return { ...state, type: { ...state.type, activeIDs: action.param.items.map((type: any) => type.id) } };

    // case types.filterTypes.TOGGLE_ACTIVE_TYPE_IDS:
    //   return { ...state, type: { ...state.type, activeIDs: action.ids } };

    // =====

    case types.filterTypes.EDIT_MODE_IN_CATEGORY:
      return { ...state, category: { ...state.category, edit: { ...state.category.edit, editMode: action.key } } };

    case types.filterTypes.EDIT_MODE_ITEM_IN_CATEGORY:
      return { ...state, category: { ...state.category, edit: { ...state.category.edit, editModeItem: action.key } } };

    case types.filterTypes.CREATE_MODE_ITEM_IN_CATEGORY:
      return { ...state, category: { ...state.category, edit: { ...state.category.edit, createModeItem: action.key } } };

    case types.filterTypes.TOGGLE_ACTIVE_CATEGORY_IDS:
      return { ...state, category: { ...state.category, activeIDs: action.ids } };

    // =====

    // case types.groupTypes.DELETE_GROUP_SUCCESS:
    //   return { ...state, group: { ...state.group, selectedItem: {} } };
    // case types.groupTypes.POST_NEW_GROUP_SUCCESS:
    //   return {
    //     ...state,
    //     group: { ...state.group, selectedItem: { name: action.response.name, id: action.response.id } },
    //   };
    // case types.groupTypes.PUT_GROUP_SUCCESS:
    //   return { ...state, group: { ...state.group, selectedItem: { ...state.group.selectedItem, name: action.response.name } } };

    // =====

    // case types.filterTypes.ADD_NEW_ITEM_IN_GROUP:
    //   return { ...state, group: { ...state.group, newItem: action.item } };
    // case types.filterTypes.DELETE_NEW_ITEM_IN_GROUP:
    //   return { ...state, group: { ...state.group, newItem: {} } };

    // case types.filterTypes.SELECT_ITEM_IN_GROUP:
    //   return { ...state, group: { ...state.group, selectedItem: action.item } };

    // case types.filterTypes.EDIT_ITEM_IN_GROUP:
    //   return { ...state, group: { ...state.group, editItem: action.item } };

    default:
      return state;
  }
};

export const filterState = {
  // type: {
  //   activeIDs: (state: any) => state.filter.type.activeIDs,
  // },

  // group: {
  //   editMode: (state: any) => state.filter.group.editMode,
  //   editModeItem: (state: any) => state.filter.group.editModeItem,
  //   newItem: (state: any) => state.filter.group.newItem,
  //   selectedItem: (state: any) => state.filter.group.selectedItem,
  //   editItem: (state: any) => state.filter.group.editItem,
  // },

  category: {
    edit: {
      editMode: (state: any) => state.filter.category.edit.editMode,
      editModeItem: (state: any) => state.filter.category.edit.editModeItem,
      createModeItem: (state: any) => state.filter.category.edit.createModeItem,
    },

    activeIDs: (state: any) => state.filter.category.activeIDs,
  },
};
