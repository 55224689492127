import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as variables from 'variables';
import * as reducer from 'store/reducers';

export interface Props extends RouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<Props> = ({ children, ...rest }: any): JSX.Element => {
  const token = useSelector(reducer.app.common.state.token);

  return (
    <Route {...rest}>
      {token ? children : (window.location.href = `https://${variables.apiRedirect}`)}
    </Route>
  );
};

export default PrivateRoute;
