export const userTypes = {
  GET_DATA_ME_SUCCESS: 'GET_DATA_ME_SUCCESS',
  GET_DATA_ME_FAILED: 'GET_DATA_ME_FAILED',
  GET_DATA_ME_PENDING: 'GET_DATA_ME_PENDING',

  GET_DATA_USER_SUCCESS: 'GET_DATA_USER_SUCCESS',
  GET_DATA_USER_FAILED: 'GET_DATA_USER_FAILED',
  GET_DATA_USER_PENDING: 'GET_DATA_USER_PENDING',

  GET_USER_PHONE_CODES_SUCCESS: 'GET_USER_PHONE_CODES_SUCCESS',
  GET_USER_PHONE_CODES_FAILED: 'GET_USER_PHONE_CODES_FAILED',
  GET_USER_PHONE_CODES_PENDING: 'GET_USER_PHONE_CODES_PENDING',

  GET_USER_SOCIAL_GOOGLE_SUCCESS: 'GET_USER_SOCIAL_GOOGLE_SUCCESS',
  GET_USER_SOCIAL_GOOGLE_FAILED: 'GET_USER_SOCIAL_GOOGLE_FAILED',
  GET_USER_SOCIAL_GOOGLE_PENDING: 'GET_USER_SOCIAL_GOOGLE_PENDING',

  GET_USER_SOCIAL_FACEBOOK_SUCCESS: 'GET_USER_SOCIAL_FACEBOOK_SUCCESS',
  GET_USER_SOCIAL_FACEBOOK_FAILED: 'GET_USER_SOCIAL_FACEBOOK_FAILED',
  GET_USER_SOCIAL_FACEBOOK_PENDING: 'GET_USER_SOCIAL_FACEBOOK_PENDING',

  POST_AVATAR_SUCCESS: 'POST_AVATAR_SUCCESS',
  POST_AVATAR_FAILED: 'POST_AVATAR_FAILED',
  POST_AVATAR_PENDING: 'POST_AVATAR_PENDING',

  POST_AUTH_GOOGLE_SUCCESS: 'POST_AUTH_GOOGLE_SUCCESS',
  POST_AUTH_GOOGLE_FAILED: 'POST_AUTH_GOOGLE_FAILED',
  POST_AUTH_GOOGLE_PENDING: 'POST_AUTH_GOOGLE_PENDING',

  POST_AUTH_FACEBOOK_SUCCESS: 'POST_AUTH_FACEBOOK_SUCCESS',
  POST_AUTH_FACEBOOK_FAILED: 'POST_AUTH_FACEBOOK_FAILED',
  POST_AUTH_FACEBOOK_PENDING: 'POST_AUTH_FACEBOOK_PENDING',

  POST_AUTH_APPLE_SUCCESS: 'POST_AUTH_APPLE_SUCCESS',
  POST_AUTH_APPLE_FAILED: 'POST_AUTH_APPLE_FAILED',
  POST_AUTH_APPLE_PENDING: 'POST_AUTH_APPLE_PENDING',

  POST_USER_CONNECT_FACEBOOK_SUCCESS: 'POST_USER_CONNECT_FACEBOOK_SUCCESS',
  POST_USER_CONNECT_FACEBOOK_FAILED: 'POST_USER_CONNECT_FACEBOOK_FAILED',
  POST_USER_CONNECT_FACEBOOK_PENDING: 'POST_USER_CONNECT_FACEBOOK_PENDING',

  POST_CONNECT_PAYPAL_SUCCESS: 'POST_CONNECT_PAYPAL_SUCCESS',
  POST_CONNECT_PAYPAL_FAILED: 'POST_CONNECT_PAYPAL_FAILED',
  POST_CONNECT_PAYPAL_PENDING: 'POST_CONNECT_PAYPAL_PENDING',

  POST_CONNECT_PAYPAL_OLD_SUCCESS: 'POST_CONNECT_PAYPAL_OLD_SUCCESS',
  POST_CONNECT_PAYPAL_OLD_FAILED: 'POST_CONNECT_PAYPAL_OLD_FAILED',
  POST_CONNECT_PAYPAL_OLD_PENDING: 'POST_CONNECT_PAYPAL_OLD_PENDING',

  POST_CONNECT_GOOGLE_CALENDAR_SUCCESS: 'POST_CONNECT_GOOGLE_CALENDAR_SUCCESS',
  POST_CONNECT_GOOGLE_CALENDAR_FAILED: 'POST_CONNECT_GOOGLE_CALENDAR_FAILED',
  POST_CONNECT_GOOGLE_CALENDAR_PENDING: 'POST_CONNECT_GOOGLE_CALENDAR_PENDING',

  POST_DISCONNECT_PAYPAL_SUCCESS: 'POST_DISCONNECT_PAYPAL_SUCCESS',
  POST_DISCONNECT_PAYPAL_FAILED: 'POST_DISCONNECT_PAYPAL_FAILED',
  POST_DISCONNECT_PAYPAL_PENDING: 'POST_DISCONNECT_PAYPAL_PENDING',

  POST_DISCONNECT_GOOGLE_CALENDAR_SUCCESS: 'POST_DISCONNECT_GOOGLE_CALENDAR_SUCCESS',
  POST_DISCONNECT_GOOGLE_CALENDAR_FAILED: 'POST_DISCONNECT_GOOGLE_CALENDAR_FAILED',
  POST_DISCONNECT_GOOGLE_CALENDAR_PENDING: 'POST_DISCONNECT_GOOGLE_CALENDAR_PENDING',

  POST_VALIDATE_USER_NAME_SUCCESS: 'POST_VALIDATE_USER_NAME_SUCCESS',
  POST_VALIDATE_USER_NAME_FAILED: 'POST_VALIDATE_USER_NAME_FAILED',
  POST_VALIDATE_USER_NAME_PENDING: 'POST_VALIDATE_USER_NAME_PENDING',

  POST_CREATE_USER_NAME_SUCCESS: 'POST_CREATE_USER_NAME_SUCCESS',
  POST_CREATE_USER_NAME_FAILED: 'POST_CREATE_USER_NAME_FAILED',
  POST_CREATE_USER_NAME_PENDING: 'POST_CREATE_USER_NAME_PENDING',

  POST_CREATE_USER_SUCCESS: 'POST_CREATE_USER_SUCCESS',
  POST_CREATE_USER_FAILED: 'POST_CREATE_USER_FAILED',
  POST_CREATE_USER_PENDING: 'POST_CREATE_USER_PENDING',

  POST_LOG_IN_SUCCESS: 'POST_LOG_IN_SUCCESS',
  POST_LOG_IN_FAILED: 'POST_LOG_IN_FAILED',
  POST_LOG_IN_PENDING: 'POST_LOG_IN_PENDING',

  POST_FORGOT_PASSWORD_SUCCESS: 'POST_FORGOT_PASSWORD_SUCCESS',
  POST_FORGOT_PASSWORD_FAILED: 'POST_FORGOT_PASSWORD_FAILED',
  POST_FORGOT_PASSWORD_PENDING: 'POST_FORGOT_PASSWORD_PENDING',

  POST_RECOVER_PASSWORD_SUCCESS: 'POST_RECOVER_PASSWORD_SUCCESS',
  POST_RECOVER_PASSWORD_FAILED: 'POST_RECOVER_PASSWORD_FAILED',
  POST_RECOVER_PASSWORD_PENDING: 'POST_RECOVER_PASSWORD_PENDING',

  PUT_DATA_ME_SUCCESS: 'PUT_DATA_ME_SUCCESS',
  PUT_DATA_ME_FAILED: 'PUT_DATA_ME_FAILED',
  PUT_DATA_ME_PENDING: 'PUT_DATA_ME_PENDING',
};
