export const initialState: any = {
  getAllOrdersMe: {
    data: null,
    status: null,
    error: null,
  },
  getOrderID: {
    data: null,
    status: null,
    error: null,
  },
  getOrderTypes: {
    data: null,
    status: null,
    error: null,
  },
};
