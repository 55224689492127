export const billingState = {
  getBillingHistory: {
    data: (state: any) => state.request.billing.getBillingHistory.data,
    links: (state: any) => state.request.billing.getBillingHistory.links,
    meta: (state: any) => state.request.billing.getBillingHistory.meta,
    status: (state: any) => state.request.billing.getBillingHistory.status,
    error: (state: any) => state.request.billing.getBillingHistory.error,
  },

  getBillingStatus: {
    data: (state: any) => state.request.billing.getBillingStatus.data,
    status: (state: any) => state.request.billing.getBillingStatus.status,
    error: (state: any) => state.request.billing.getBillingStatus.error,
  },
};
