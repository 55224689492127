export const itemsType = {
  GET_ALL_ITEMS_ME_SUCCESS: 'GET_ALL_ITEMS_ME_SUCCESS',
  GET_ALL_ITEMS_ME_FAILED: 'GET_ALL_ITEMS_ME_FAILED',
  GET_ALL_ITEMS_ME_PENDING: 'GET_ALL_ITEMS_ME_PENDING',

  GET_MORE_ITEMS_ME_SUCCESS: 'GET_MORE_ITEMS_ME_SUCCESS',
  GET_MORE_ITEMS_ME_FAILED: 'GET_MORE_ITEMS_ME_FAILED',
  GET_MORE_ITEMS_ME_PENDING: 'GET_MORE_ITEMS_ME_PENDING',

  GET_ALL_ITEMS_USER_SUCCESS: 'GET_ALL_ITEMS_USER_SUCCESS',
  GET_ALL_ITEMS_USER_FAILED: 'GET_ALL_ITEMS_USER_FAILED',
  GET_ALL_ITEMS_USER_PENDING: 'GET_ALL_ITEMS_USER_PENDING',

  GET_MORE_ITEMS_USER_SUCCESS: 'GET_MORE_ITEMS_USER_SUCCESS',
  GET_MORE_ITEMS_USER_FAILED: 'GET_MORE_ITEMS_USER_FAILED',
  GET_MORE_ITEMS_USER_PENDING: 'GET_MORE_ITEMS_USER_PENDING',

  GET_ITEM_ID_SUCCESS: 'GET_ITEM_ID_SUCCESS',
  GET_ITEM_ID_FAILED: 'GET_ITEM_ID_FAILED',
  GET_ITEM_ID_PENDING: 'GET_ITEM_ID_PENDING',

  POST_ITEM_SUCCESS: 'POST_ITEM_SUCCESS',
  POST_ITEM_FAILED: 'POST_ITEM_FAILED',
  POST_ITEM_PENDING: 'POST_ITEM_PENDING',

  POST_IMAGE_SUCCESS: 'POST_IMAGE_SUCCESS',
  POST_IMAGE_FAILED: 'POST_IMAGE_FAILED',
  POST_IMAGE_PENDING: 'POST_IMAGE_PENDING',

  PUT_ITEM_ID_SUCCESS: 'PUT_ITEM_ID_SUCCESS',
  PUT_ITEM_ID_FAILED: 'PUT_ITEM_ID_FAILED',
  PUT_ITEM_ID_PENDING: 'PUT_ITEM_ID_PENDING',

  DELETE_ITEM_ID_SUCCESS: 'DELETE_ITEM_ID_SUCCESS',
  DELETE_ITEM_ID_FAILED: 'DELETE_ITEM_ID_FAILED',
  DELETE_ITEM_ID_PENDING: 'DELETE_ITEM_ID_PENDING',

  DELETE_ITEMS_SUCCESS: 'DELETE_ITEMS_SUCCESS',
  DELETE_ITEMS_FAILED: 'DELETE_ITEMS_FAILED',
  DELETE_ITEMS_PENDING: 'DELETE_ITEMS_PENDING',
};
