export const paypalType = {
  GET_CONNECT_PAYPAL_SUCCESS: 'GET_CONNECT_PAYPAL_SUCCESS',
  GET_CONNECT_PAYPAL_FAILED: 'GET_CONNECT_PAYPAL_FAILED',
  GET_CONNECT_PAYPAL_PENDING: 'GET_CONNECT_PAYPAL_PENDING',

  GET_CHECK_SUBSCRIBE_PAYPAL_SUCCESS: 'GET_CHECK_SUBSCRIBE_PAYPAL_SUCCESS',
  GET_CHECK_SUBSCRIBE_PAYPAL_FAILED: 'GET_CHECK_SUBSCRIBE_PAYPAL_FAILED',
  GET_CHECK_SUBSCRIBE_PAYPAL_PENDING: 'GET_CHECK_SUBSCRIBE_PAYPAL_PENDING',

  GET_URL_PAYPAL_SUCCESS: 'GET_URL_PAYPAL_SUCCESS',
  GET_URL_PAYPAL_FAILED: 'GET_URL_PAYPAL_FAILED',
  GET_URL_PAYPAL_PENDING: 'GET_URL_PAYPAL_PENDING',
};
