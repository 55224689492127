export const filterTypes = {
  // REQUEST_UPDATE_ITEM_IN_GROUP: 'REQUEST_UPDATE_ITEM_IN_GROUP',
  // REQUEST_DELETE_ITEM_IN_GROUP: 'REQUEST_DELETE_ITEM_IN_GROUP',

  // EDIT_MODE_IN_GROUP: 'EDIT_MODE_IN_GROUP',
  // EDIT_MODE_ITEM_IN_GROUP: 'EDIT_MODE_ITEM_IN_GROUP',

  // ADD_NEW_ITEM_IN_GROUP: 'ADD_NEW_ITEM_GROUP',
  // DELETE_NEW_ITEM_IN_GROUP: 'DELETE_NEW_ITEM_IN_GROUP',
  // SELECT_ITEM_IN_GROUP: 'SELECT_ITEM_IN_GROUP',
  // EDIT_ITEM_IN_GROUP: 'EDIT_ITEM_IN_GROUP',

  EDIT_MODE_IN_CATEGORY: 'EDIT_MODE_IN_CATEGORY',
  EDIT_MODE_ITEM_IN_CATEGORY: 'EDIT_MODE_ITEM_IN_CATEGORY',
  CREATE_MODE_ITEM_IN_CATEGORY: 'CREATE_MODE_ITEM_IN_CATEGORY',
  TOGGLE_ACTIVE_CATEGORY_IDS: 'TOGGLE_ACTIVE_CATEGORY_IDS',

  TOGGLE_ACTIVE_TYPE_IDS: 'TOGGLE_ACTIVE_TYPE_IDS'
};
