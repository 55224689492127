import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/delivery';

export const reducer = createReducer(initialState, (builder) => {
  builder

    //-----getAllDeliveriesMe
    .addCase(action.getAllDeliveriesMe.getSuccess, ({ getAllDeliveriesMe }, action) => {
      getAllDeliveriesMe.status = 'success';
      getAllDeliveriesMe.data = action.payload.items;
    })
    .addCase(action.getAllDeliveriesMe.getFailed, ({ getAllDeliveriesMe }, action) => {
      getAllDeliveriesMe.status = 'failed';
      getAllDeliveriesMe.error = action.payload;
    })
    .addCase(action.getAllDeliveriesMe.getPending, ({ getAllDeliveriesMe }, action) => {
      getAllDeliveriesMe.status = 'pending';
      getAllDeliveriesMe.error = null;
    })

    //-----getAllDeliveryFees
    .addCase(action.getAllDeliveryFees.getSuccess, ({ getAllDeliveryFees }, action) => {
      getAllDeliveryFees.status = 'success';
      getAllDeliveryFees.data = action.payload.items;
    })
    .addCase(action.getAllDeliveryFees.getFailed, ({ getAllDeliveryFees }, action) => {
      getAllDeliveryFees.status = 'failed';
      getAllDeliveryFees.error = action.payload;
    })
    .addCase(action.getAllDeliveryFees.getPending, ({ getAllDeliveryFees }, action) => {
      getAllDeliveryFees.status = 'pending';
      getAllDeliveryFees.error = null;
    });
});
