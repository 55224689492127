import React, { useEffect } from 'react';
import Router from './Router';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import smoothscroll from 'smoothscroll-polyfill';

import * as action from './store/actions';
import * as util from './utils';

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://f6eeb43530cf4bea90bd23ab87773608@o561687.ingest.sentry.io/5700949',
    environment: 'production',
    tracesSampleRate: 1.0,
  });
} else if (process.env.REACT_APP_ENV === 'staging') {
  Sentry.init({
    dsn: 'https://f6eeb43530cf4bea90bd23ab87773608@o561687.ingest.sentry.io/5700949',
    environment: 'staging',
    tracesSampleRate: 1.0,
  });
}

// Sentry.init({
//   dsn: 'https://f6eeb43530cf4bea90bd23ab87773608@o561687.ingest.sentry.io/5700949',
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   environment: 'production',
// });

const App: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const addToken = (data: any) => dispatch(action.app.commonAction.addToken(data));

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  useEffect(() => {
    const token: any = util.cookie.get('token');
    if (token) addToken(token);
  }, []);

  smoothscroll.polyfill();

  useEffect(() => {
    document.addEventListener('touchmove', (e) => e.preventDefault(), false);
  }, []);

  return <Router />;
};

export default App;
