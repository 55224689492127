export const facebookBusiness = {
  GET_FACEBOOK_SUITE_ME_SUCCESS: 'GET_FACEBOOK_SUITE_ME_SUCCESS',
  GET_FACEBOOK_SUITE_ME_FAILED: 'GET_FACEBOOK_SUITE_ME_FAILED',
  GET_FACEBOOK_SUITE_ME_PENDING: 'GET_FACEBOOK_SUITE_ME_PENDING',

  PUT_FACEBOOK_SUITE_UPDATE_SUCCESS: 'PUT_FACEBOOK_SUITE_UPDATE_SUCCESS',
  PUT_FACEBOOK_SUITE_UPDATE_FAILED: 'PUT_FACEBOOK_SUITE_UPDATE_FAILED',
  PUT_FACEBOOK_SUITE_UPDATE_PENDING: 'PUT_FACEBOOK_SUITE_UPDATE_PENDING',

  POST_FACEBOOK_SUITE_DISCONNECT_SUCCESS: 'POST_FACEBOOK_SUITE_DISCONNECT_SUCCESS',
  POST_FACEBOOK_SUITE_DISCONNECT_FAILED: 'POST_FACEBOOK_SUITE_DISCONNECT_FAILED',
  POST_FACEBOOK_SUITE_DISCONNECT_PENDING: 'POST_FACEBOOK_SUITE_DISCONNECT_PENDING',
};
