import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/unit';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.getAllUnits.getSuccess, ({ getAllUnits }, action) => {
      getAllUnits.status = 'success';
      getAllUnits.data = action.payload;
    })
    .addCase(action.getAllUnits.getFailed, ({ getAllUnits }, action) => {
      getAllUnits.status = 'failed';
      getAllUnits.error = action.payload;
    })
    .addCase(action.getAllUnits.getPending, ({ getAllUnits }, action) => {
      getAllUnits.status = 'pending';
      getAllUnits.error = null;
    });
});
