export const initialState: any = {
  getAllDeliveriesMe: {
    data: null,
    status: null,
    error: null,
  },

  getAllDeliveryFees: {
    data: null,
    status: null,
    error: null,
  },
};
