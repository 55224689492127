import * as util from 'utils';
import * as variables from 'variables';

export const locationApi = {
  getAllLocationsMe: () => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/service-locations/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
