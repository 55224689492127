export const categoryType = {
  GET_ALL_CATEGORIES_ME_SUCCESS: 'GET_ALL_CATEGORIES_ME_SUCCESS',
  GET_ALL_CATEGORIES_ME_FAILED: 'GET_ALL_CATEGORIES_ME_FAILED',
  GET_ALL_CATEGORIES_ME_PENDING: 'GET_ALL_CATEGORIES_ME_PENDING',

  GET_ALL_CATEGORIES_USER_SUCCESS: 'GET_ALL_CATEGORIES_USER_SUCCESS',
  GET_ALL_CATEGORIES_USER_FAILED: 'GET_ALL_CATEGORIES_USER_FAILED',
  GET_ALL_CATEGORIES_USER_PENDING: 'GET_ALL_CATEGORIES_USER_PENDING',

  POST_CATEGORY_SUCCESS: 'POST_CATEGORY_SUCCESS',
  POST_CATEGORY_FAILED: 'POST_CATEGORY_FAILED',
  POST_CATEGORY_PENDING: 'POST_CATEGORY_PENDING',

  POST_CATEGORIES_SUCCESS: 'POST_CATEGORIES_SUCCESS',
  POST_CATEGORIES_FAILED: 'POST_CATEGORIES_FAILED',
  POST_CATEGORIES_PENDING: 'POST_CATEGORIES_PENDING',

  PUT_CATEGORY_ID_SUCCESS: 'PUT_CATEGORY_ID_SUCCESS',
  PUT_CATEGORY_ID_FAILED: 'PUT_CATEGORY_ID_FAILED',
  PUT_CATEGORY_ID_PENDING: 'PUT_CATEGORY_ID_PENDING',

  PUT_CATEGORIES_SUCCESS: 'PUT_CATEGORIES_SUCCESS',
  PUT_CATEGORIES_FAILED: 'PUT_CATEGORIES_FAILED',
  PUT_CATEGORIES_PENDING: 'PUT_CATEGORIES_PENDING',

  DELETE_CATEGORY_ID_SUCCESS: 'DELETE_CATEGORY_ID_SUCCESS',
  DELETE_CATEGORY_ID_FAILED: 'DELETE_CATEGORY_ID_FAILED',
  DELETE_CATEGORY_ID_PENDING: 'DELETE_CATEGORY_ID_PENDING',

  DELETE_CATEGORIES_SUCCESS: 'DELETE_CATEGORIES_SUCCESS',
  DELETE_CATEGORIES_FAILED: 'DELETE_CATEGORIES_FAILED',
  DELETE_CATEGORIES_PENDING: 'DELETE_CATEGORIES_PENDING',
};
