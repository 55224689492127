export const initialState: any = {
  getDataMe: {
    data: null,
    status: null,
    error: null,
  },

  getDataUser: {
    data: null,
    status: null,
    error: null,
  },

  getUserPhoneCodes: {
    data: null,
    status: null,
    error: null,
  },

  getUserSocialGoogle: {
    data: null,
    status: null,
    error: null,
  },

  getUserSocialFacebook: {
    data: null,
    status: null,
    error: null,
  },

  postAvatar: {
    data: null,
    status: null,
    error: null,
  },

  postAuthGoogle: {
    data: null,
    status: null,
    error: null,
  },

  postAuthFacebook: {
    data: null,
    status: null,
    error: null,
  },

  postAuthApple: {
    data: null,
    status: null,
    error: null,
  },

  postUserConnectFacebook: {
    data: null,
    status: null,
    error: null,
  },

  postConnectPaypal: {
    data: null,
    status: null,
    error: null,
  },

  postConnectPaypalOld: {
    data: null,
    status: null,
    error: null,
  },

  postConnectGoogleCalendar: {
    data: null,
    status: null,
    error: null,
  },

  postDisconnectPaypal: {
    data: null,
    status: null,
    error: null,
  },

  postDisconnectGoogleCalendar: {
    data: null,
    status: null,
    error: null,
  },

  postValidateUserName: {
    data: null,
    status: null,
    error: null,
  },

  postCreateUserName: {
    data: null,
    status: null,
    error: null,
  },

  postCreateUser: {
    data: null,
    status: null,
    error: null,
  },

  postLogIn: {
    data: null,
    status: null,
    error: null,
  },

  postForgotPassword: {
    data: null,
    status: null,
    error: null,
  },

  postRecoverPassword: {
    data: null,
    status: null,
    error: null,
  },

  putDataMe: {
    data: null,
    status: null,
    error: null,
  },
};
