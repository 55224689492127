import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import './style.scss';

import * as variables from 'variables';
import * as action from 'store/actions';
import * as reducer from 'store/reducers';
import * as payUtils from './payUtils';

import SmallPopupWrapper from '../smallPopupWrapper';

declare global {
  interface Window {
    paypal_sdk: any;
  }
}

interface Props {
  visible: boolean;
  payType: string;
  setShowPayPopup: (pram: boolean) => void;
  setShowPopup: (pram: boolean) => void;
}

const PayPopup: React.FC<Props> = ({
  visible,
  payType,
  setShowPayPopup,
  setShowPopup,
}): JSX.Element => {
  const [isScroll, setScroll] = useState(false);

  const dispatch: any = useDispatch();
  const history = useHistory();

  const allItemsForCart = useSelector(reducer.request.cart.state.getAllItemsForCart.data);
  const plans = useSelector(reducer.request.plan.state.getAllPlansMe.data);
  const dataUser = useSelector(reducer.request.user.state.getDataUser.data);
  const dataMe = useSelector(reducer.request.user.state.getDataMe.data);

  const serviceToCart = useSelector(reducer.request.cart.state.addServiceToCart.data);

  const subdomain = useSelector(reducer.app.common.state.subdomain);

  const getCheckedSubscribe = (id: any) =>
    dispatch(action.request.paypal.getCheckSubscribePaypal.get(id));

  const showMainSpinner = () => dispatch(action.app.commonAction.mainSpinner.show());
  const hideMainSpinner = () => dispatch(action.app.commonAction.mainSpinner.hide());
  const addCheckedItems = (items: any) => dispatch(action.app.cartAction.addCheckedItems(items));

  const isTypePlan = payType === 'plan';
  const isTypeCart = payType === 'cart';
  const isTypeService = payType === 'service';

  const data = ((isTypeCart || isTypeService) && dataUser) || (isTypePlan && dataMe);
  const price =
    (isTypeCart && allItemsForCart?.total_price_formatted) ||
    (isTypeService && serviceToCart?.total_price_formatted) ||
    (isTypePlan && plans?.find((plan: any) => dataMe?.next_plan_id === plan.id))?.price;
  const payPalScript =
    ((isTypeCart || isTypeService) &&
      `https://www.paypal.com/sdk/js?client-id=${variables.apiKey}&enable-funding=venmo&currency=${dataUser?.currency}&merchant-id=${dataUser?.connected_info.paypal?.merchant_id}`) ||
    (isTypePlan &&
      `https://www.paypal.com/sdk/js?client-id=${variables.apiKey}&vault=true&intent=subscription`);

  useEffect(() => {
    if (data?.connected_info?.paypal) {
      const PAYPAL_SCRIPT = payPalScript.toString();
      const script = document.createElement('script');

      script.setAttribute('src', PAYPAL_SCRIPT);
      script.setAttribute('data-namespace', 'paypal_sdk');
      document.head.appendChild(script);
    }
  }, [data]);

  const onApproveSuccessCartRequest = () => {
    addCheckedItems([]);
    setShowPayPopup(false);
    setShowPopup(false);

    setTimeout(() => history.push('/order'), 500);
  };

  const onApproveSuccessPlanRequest = (id: number) => {
    setShowPayPopup(false);
    setShowPopup(false);
    getCheckedSubscribe(id).then(() => setTimeout(() => history.push('/my-settings', 500)));
  };

  useEffect(() => {
    if (visible) {
      (isTypePlan && payUtils.plan(dataMe?.next_plan_id, onApproveSuccessPlanRequest)) ||
        (isTypeCart &&
          payUtils.cart(
            subdomain,
            setScroll,
            showMainSpinner,
            hideMainSpinner,
            onApproveSuccessCartRequest
          )) ||
        (isTypeService &&
          payUtils.service(
            subdomain,
            setScroll,
            showMainSpinner,
            hideMainSpinner,
            onApproveSuccessCartRequest
          ));
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const target: any = document.querySelector('.paypal-buttons-context-iframe');

      const config = {
        attributes: true,
        childList: false,
        subtree: false,
      };

      const callback = function (mutationsList: any, observer: any) {
        for (let mutation of mutationsList) {
          console.log(
            'The ' + mutation.attributeName + ' attribute was modified.',
            mutation.target.clientHeight
          );
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(target, config);

      return () => observer.disconnect();
    }
  }, [visible]);

  useEffect(() => {
    visible
      ? setTimeout(() => document.body.classList.add('no-scroll'), 500)
      : document.body.classList.remove('no-scroll');
  }, [visible]);

  return (
    <SmallPopupWrapper
      visible={visible}
      title=""
      customClass="pay-popup"
      substrateColor="black"
      onClose={() => setShowPayPopup(false)}
    >
      <p className="total-price">
        <span className="total-price-title">Total:</span>
        <span className="currency">{data?.currency} </span>
        <span className="price">{price}</span>
      </p>

      <section className="settings-button-box" id="paypal-button-container"></section>
    </SmallPopupWrapper>
  );
};
export default PayPopup;
