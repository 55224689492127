export const cartType = {
  getAllItemsForCartSuccess: 'request/getAllItemsForCartSuccess',
  getAllItemsForCartFailed: 'request/getAllItemsForCartFailed',
  getAllItemsForCartPending: 'request/getAllItemsForCartPending',

  getAllItemsSuccessfulForCartSuccess: 'request/getAllItemsSuccessfulForCartSuccess',
  getAllItemsSuccessfulForCartFailed: 'request/getAllItemsSuccessfulForCartFailed',
  getAllItemsSuccessfulForCartPending: 'request/getAllItemsSuccessfulForCartPending',

  addProductToCartSuccess: 'request/addProductToCartSuccess',
  addProductToCartFailed: 'request/addProductToCartFailed',
  addProductToCartPending: 'request/addProductToCartPending',

  addServiceToCartSuccess: 'request/addServiceToCartSuccess',
  addServiceToCartFailed: 'request/addServiceToCartFailed',
  addServiceToCartPending: 'request/addServiceToCartPending',

  addProductsToCartSuccess: 'request/addProductsToCartSuccess',
  addProductsToCartFailed: 'request/addProductsToCartFailed',
  addProductsToCartPending: 'request/addProductsToCartPending',

  changeQuantityForProductInCartSuccess: 'request/changeQuantityForProductInCartSuccess',
  changeQuantityForProductInCartFailed: 'request/changeQuantityForProductInCartFailed',
  changeQuantityForProductInCartPending: 'request/changeQuantityForProductInCartPending',

  changeQuantityForProductsInCartSuccess: 'request/changeQuantityForProductsInCartSuccess',
  changeQuantityForProductsInCartFailed: 'request/changeQuantityForProductsInCartFailed',
  changeQuantityForProductsInCartPending: 'request/changeQuantityForProductsInCartPending',

  changeDeliveryForProductsInCartSuccess: 'request/changeDeliveryForProductsInCartSuccess',
  changeDeliveryForProductsInCartFailed: 'request/changeDeliveryForProductsInCartFailed',
  changeDeliveryForProductsInCartPending: 'request/changeDeliveryForProductsInCartPending',

  changeAddressForProductsInCartSuccess: 'request/changeAddressForProductsInCartSuccess',
  changeAddressForProductsInCartFailed: 'request/changeAddressForProductsInCartFailed',
  changeAddressForProductsInCartPending: 'request/changeAddressForProductsInCartPending',

  deleteItemFromCartSuccess: 'request/deleteItemFromCartSuccess',
  deleteItemFromCartFailed: 'request/deleteItemFromCartFailed',
  deleteItemFromCartPending: 'request/deleteItemFromCartPending',
};
