import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import * as util from 'utils';

export interface Props extends RouteProps {
  children: React.ReactNode;
}

const PrivateRouteLogin: React.FC<Props> = ({ children, ...rest }: any): JSX.Element => {
  return <Route {...rest}>{util.cookie.get('token') ? children : <Redirect to="/" />}</Route>;
};

export default PrivateRouteLogin;
