export const state = {
  getAllDeliveriesMe: {
    data: (state: any) => state.request.delivery.getAllDeliveriesMe.data,
    status: (state: any) => state.request.delivery.getAllDeliveriesMe.status,
    error: (state: any) => state.request.delivery.getAllDeliveriesMe.error,
  },

  getAllDeliveryFees: {
    data: (state: any) => state.request.delivery.getAllDeliveryFees.data,
    status: (state: any) => state.request.delivery.getAllDeliveryFees.status,
    error: (state: any) => state.request.delivery.getAllDeliveryFees.error,
  },
};
