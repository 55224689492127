import * as util from 'utils';
import * as variables from 'variables';

export const paypalApi = {
  getConnectPaypal: (param: string) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/paypals/connect${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getCheckSubscribePaypal: (id: number) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/paypals/check-subscribe/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getUrlPaypal: (param: string) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/paypals/url?location=${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
