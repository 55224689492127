export const orderType = {
  GET_ALL_ORDERS_ME_SUCCESS: 'GET_ALL_ORDERS_ME_SUCCESS',
  GET_ALL_ORDERS_ME_FAILED: 'GET_ALL_ORDERS_ME_FAILED',
  GET_ALL_ORDERS_ME_PENDING: 'GET_ALL_ORDERS_ME_PENDING',

  GET_ORDER_ID_SUCCESS: 'GET_ORDER_ID_SUCCESS',
  GET_ORDER_ID_FAILED: 'GET_ORDER_ID_FAILED',
  GET_ORDER_ID_PENDING: 'GET_ORDER_ID_PENDING',

  GET_ORDER_TYPES_SUCCESS: 'GET_ORDER_TYPES_SUCCESS',
  GET_ORDER_TYPES_FAILED: 'GET_ORDER_TYPES_FAILED',
  GET_ORDER_TYPES_PENDING: 'GET_ORDER_TYPES_PENDING',
};
