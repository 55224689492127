import { createAction } from '@reduxjs/toolkit';

import * as api from 'api';
import * as type from 'store/types';

export const changeQuantityForProductInCart = {
  put: function (userName: string, data: { item_id: number; quantity: number }) {
    return (dispatch: any) => {
      dispatch(this.putPending());

      return api.cartApi
        .changeQuantityForProductInCart(userName, data)
        .then((response: any) => {
          if (response.status === 200) {
            return response.json().then((json: any) => dispatch(this.putSuccess(json)));
          } else {
            return response.json().then((json: any) => {
              throw new Error(JSON.stringify(json));
            });
          }
        })
        .catch((error: any) => {
          dispatch(this.putFailed(JSON.parse(error.message)));
          throw new Error();
        });
    };
  },

  putSuccess: createAction<any>(type.request.cartType.changeQuantityForProductInCartSuccess),
  putFailed: createAction<any>(type.request.cartType.changeQuantityForProductInCartFailed),
  putPending: createAction(type.request.cartType.changeQuantityForProductInCartPending),
};
