import * as types from 'store/types';

export const filterActions = {
  editModeInCategory: (key: boolean) => ({
    type: types.filterTypes.EDIT_MODE_IN_CATEGORY,
    key,
  }),

  editModeItemInCategory: (key: boolean) => ({
    type: types.filterTypes.EDIT_MODE_ITEM_IN_CATEGORY,
    key,
  }),

  createModeItemInCategory: (key: boolean) => ({
    type: types.filterTypes.CREATE_MODE_ITEM_IN_CATEGORY,
    key,
  }),

  toggleActiveCategoryIDs: (ids: number[]) => ({
    type: types.filterTypes.TOGGLE_ACTIVE_CATEGORY_IDS,
    ids,
  }),
};
