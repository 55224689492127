import { createAction } from '@reduxjs/toolkit';

import * as api from 'api';
import * as type from 'store/types';

export const putItemID = {
  put: function (id: number, data: {}) {
    return (dispatch: any) => {
      dispatch(this.putPending());

      return api.itemApi
        .putItemID(id, data)
        .then((response: any) => {
          if (response.status === 200) {
            return response.json().then((json: any) => dispatch(this.putSuccess(json)));
          } else {
            return response.json().then((json: any) => {
              throw new Error(JSON.stringify(json));
            });
          }
        })
        .catch((error: any) => {
          dispatch(this.putFailed(JSON.parse(error.message)));
          throw new Error();
        });
    };
  },

  putSuccess: createAction<any>(type.request.itemsType.PUT_ITEM_ID_SUCCESS),
  putFailed: createAction<any>(type.request.itemsType.PUT_ITEM_ID_FAILED),
  putPending: createAction(type.request.itemsType.PUT_ITEM_ID_PENDING),
};
