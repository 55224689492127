export const fullScreen = {
  contentStyle: {
    hidden: {
      x: '-100vw',
      transition: {
        duration: 0.3,
      },
    },

    show: {
      x: 0,
      transition: {
        type: 'spring',
        duration: 0.3,
        delay: 0.2,
      },
    },
  },

  containerStyle: {
    hidden: {
      left: '-200vw',
      transition: {
        ease: 'easeIn',
        duration: 0.3,
      },
    },
    show: {
      left: 0,
      transition: {
        ease: 'easeIn',
        duration: 0.3,
      },
    },
  },
};

export const halfScreen = {
  contentStyle: {
    hidden: {
      y: -100,
      transition: {
        duration: 0.3,
      },
    },

    show: {
      y: 0,
      transition: {
        type: 'spring',
        duration: 0.3,
        delay: 0.2,
      },
    },
  },

  containerStyle: {
    hidden: {
      bottom: '-100%',
      transition: {
        ease: 'easeIn',
        duration: 0.3,
      },
    },
    show: {
      bottom: 0,
      transition: {
        ease: 'easeIn',
        duration: 0.3,
      },
    },
  },

  substrateStyle: {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.3,
      },
    },
    show: {
      opacity: 0.7,
      transition: {
        duration: 0.3,
      },
    },
  },
};

export const countryPopup = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },

}
