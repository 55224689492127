export const commonType = {
  SHOW_MAIN_SPINNER: 'SHOW_MAIN_SPINNER',
  HIDE_MAIN_SPINNER: 'HIDE_MAIN_SPINNER',

  OPEN_MAIN_NAV: 'OPEN_MAIN_NAV',
  CLOSE_MAIN_NAV: 'CLOSE_MAIN_NAV',

  addToken: 'app/addToken',
  addSubdomain: 'app/addSubdomain',
};
