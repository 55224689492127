import { createReducer } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import * as action from 'store/actions/_request/facebookBusiness';

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(action.getFacebookSuiteMe.getSuccess, ({ getFacebookSuiteMe }, action) => {
      getFacebookSuiteMe.status = 'success';
      getFacebookSuiteMe.data = action.payload;
    })
    .addCase(action.getFacebookSuiteMe.getFailed, ({ getFacebookSuiteMe }, action) => {
      getFacebookSuiteMe.status = 'failed';
      getFacebookSuiteMe.error = action.payload;
    })
    .addCase(action.getFacebookSuiteMe.getPending, ({ getFacebookSuiteMe }, action) => {
      getFacebookSuiteMe.status = 'pending';
      getFacebookSuiteMe.error = null;
    });

  builder
    .addCase(action.putFacebookSuiteUpdate.putSuccess, ({ getFacebookSuiteMe, putFacebookSuiteUpdate }, action) => {
      putFacebookSuiteUpdate.status = 'success';
      getFacebookSuiteMe.data = { ...getFacebookSuiteMe.data, ...action.payload };
    })
    .addCase(action.putFacebookSuiteUpdate.putFailed, ({ putFacebookSuiteUpdate }, action) => {
      putFacebookSuiteUpdate.status = 'failed';
      putFacebookSuiteUpdate.error = action.payload;
    })
    .addCase(action.putFacebookSuiteUpdate.putPending, ({ putFacebookSuiteUpdate }, action) => {
      putFacebookSuiteUpdate.status = 'pending';
      putFacebookSuiteUpdate.error = null;
    });

  builder
    .addCase(action.postFacebookSuiteDisconnect.postSuccess, ({ postFacebookSuiteDisconnect }, action) => {
      postFacebookSuiteDisconnect.status = 'success';
    })
    .addCase(action.postFacebookSuiteDisconnect.postFailed, ({ postFacebookSuiteDisconnect }, action) => {
      postFacebookSuiteDisconnect.status = 'failed';
      postFacebookSuiteDisconnect.error = action.payload;
    })
    .addCase(action.postFacebookSuiteDisconnect.postPending, ({ postFacebookSuiteDisconnect }, action) => {
      postFacebookSuiteDisconnect.status = 'pending';
      postFacebookSuiteDisconnect.error = null;
    });
});
