export const state = {
  getAllItemsForCart: {
    data: (state: any) => state.request.cart.getAllItemsForCart.data,
    status: (state: any) => state.request.cart.getAllItemsForCart.status,
    error: (state: any) => state.request.cart.getAllItemsForCart.error,
  },

  getAllItemsSuccessfulForCart: {
    data: (state: any) => state.request.cart.getAllItemsSuccessfulForCart.data,
    status: (state: any) => state.request.cart.getAllItemsSuccessfulForCart.status,
    error: (state: any) => state.request.cart.getAllItemsSuccessfulForCart.error,
  },

  addProductToCart: {
    status: (state: any) => state.request.cart.addProductToCart.status,
    error: (state: any) => state.request.cart.addProductToCart.error,
  },

  addServiceToCart: {
    data: (state: any) => state.request.cart.addServiceToCart.data,
    status: (state: any) => state.request.cart.addServiceToCart.status,
    error: (state: any) => state.request.cart.addServiceToCart.error,
  },

  addProductsToCart: {
    status: (state: any) => state.request.cart.addProductsToCart.status,
    error: (state: any) => state.request.cart.addProductsToCart.error,
  },

  changeQuantityForProductInCart: {
    status: (state: any) => state.request.cart.changeQuantityForProductInCart.status,
    error: (state: any) => state.request.cart.changeQuantityForProductInCart.error,
  },

  changeQuantityForProductsInCart: {
    status: (state: any) => state.request.cart.changeQuantityForProductsInCart.status,
    error: (state: any) => state.request.cart.changeQuantityForProductsInCart.error,
  },

  changeDeliveryForProductsInCart: {
    status: (state: any) => state.request.cart.changeDeliveryForProductsInCart.status,
    error: (state: any) => state.request.cart.changeDeliveryForProductsInCart.error,
  },

  changeAddressForProductsInCart: {
    status: (state: any) => state.request.cart.changeAddressForProductsInCart.status,
    error: (state: any) => state.request.cart.changeAddressForProductsInCart.error,
  },

  deleteItemFromCart: {
    status: (state: any) => state.request.cart.deleteItemFromCart.status,
    error: (state: any) => state.request.cart.deleteItemFromCart.error,
  },
};
