export const initialState: any = {
  getAllCategoriesMe: {
    data: [],
    status: null,
    error: null,
  },

  getAllCategoriesUser: {
    data: [],
    status: null,
    error: null,
  },

  postCategory: {
    data: {},
    status: null,
    error: null,
  },

  postCategories: {
    status: null,
    error: null,
  },

  putCategoryID: {
    status: null,
    error: null,
  },

  putCategories: {
    status: null,
    error: null,
  },

  deleteCategoryID: {
    status: null,
    error: null,
  },

  deleteCategories: {
    status: null,
    error: null,
  },
};
