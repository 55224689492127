import React, { useEffect, useState, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as action from './store/actions';

import PrivateRouteLogin from './HOC/PrivateRouteLogin';
import PrivateRoute from './HOC/PrivateRoute';

import ShowAlertPopup from './HOC/ShowAlertPopup';

const AgreePrivacyPolicy = lazy(() => import('./pages/agreePrivacyPolicy'));
const AgreeTermsConditions = lazy(() => import('./pages/agreeTermsConditions'));

const Connect = lazy(() => import('./pages/connect/'));

const P404 = lazy(() => import('./pages/P404'));

const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const SignIn = lazy(() => import('pages/signIn/'));
const SignUp = lazy(() => import('pages/signUp/'));
const SignUpEmail = lazy(() => import('pages/signUpEmail/'));
const SignUpEmailStepOne = lazy(() => import('pages/signUpEmailStepOne'));
const SignUpEmailStepTwo = lazy(() => import('pages/signUpEmailStepTwo'));

const MySettings = lazy(() => import('./pages/mySettings/'));

const ProductGallery = lazy(() => import('./pages/ProductGallery'));
const PGOrder = lazy(() => import('./pages/pgOrder'));
const PGProduct = lazy(() => import('./pages/pgProduct'));
const PGService = lazy(() => import('./pages/pgService'));

const MyInventory = lazy(() => import('./pages/MyInventory'));
const MyProduct = lazy(() => import('./pages/myProduct/'));
const MyService = lazy(() => import('./pages/myService/'));
const MyAddItem = lazy(() => import('./pages/myAddItem'));

const MyOrder = lazy(() => import('./pages/MyOrder'));
const MyOrders = lazy(() => import('./pages/MyOrders'));

const Router: React.FC = (): JSX.Element => {
  const [subdomain, setSubdomain] = useState('');

  const dispatch = useDispatch();

  const addSubdomain = (data: any) => dispatch(action.app.commonAction.addSubdomain(data));

  useEffect(() => {
    const host = window.location.host.split('.');

    if (host.length >= 3) {
      const subdomain = host[0];
      const currentSubdomain = subdomain === 'dev' ? 'testshop' : subdomain;

      setSubdomain(currentSubdomain);
      addSubdomain(currentSubdomain);
    }
  }, []);

  if (subdomain === 'login') {
    return (
      <Switch>
        <Route exact path="/reset-password">
          <ResetPassword />
        </Route>

        <Route exact path="/">
          <SignIn />
        </Route>

        <Route>
          <P404 />
        </Route>
      </Switch>
    );
  }

  if (subdomain === 'signup') {
    return (
      <Switch>
        <PrivateRouteLogin exact path="/step-one">
          <SignUpEmailStepOne />
        </PrivateRouteLogin>

        <PrivateRouteLogin exact path="/step-two">
          <SignUpEmailStepTwo />
        </PrivateRouteLogin>

        <Route exact path="/email">
          <SignUpEmail />
        </Route>

        <Route exact path="/privacy_policy">
          <AgreePrivacyPolicy />
        </Route>

        <Route exact path="/terms_and_conditions">
          <AgreeTermsConditions />
        </Route>

        <Route exact path="/">
          <SignUp />
        </Route>

        <Route>
          <P404 />
        </Route>
      </Switch>
    );
  }

  if (subdomain === 'connect') {
    return (
      <PrivateRoute exact path="/">
        <Connect />
      </PrivateRoute>
    );
  }

  if (subdomain) {
    return (
      <Switch>
        <Route exact path="/product/:id">
          <PGProduct />
        </Route>

        <Route exact path="/service/:id">
          <PGService />
        </Route>

        <Route exact path="/order">
          <PGOrder />
        </Route>

        <Route exact path="/">
          <ProductGallery />
        </Route>

        <PrivateRoute exact path="/my-inventory/service/:id">
          <ShowAlertPopup>
            <MyService />
          </ShowAlertPopup>
        </PrivateRoute>

        <PrivateRoute exact path="/my-inventory/product/:id">
          <ShowAlertPopup>
            <MyProduct />
          </ShowAlertPopup>
        </PrivateRoute>

        <PrivateRoute exact path="/my-inventory/edit-item/:id">
          <ShowAlertPopup>
            <MyAddItem />
          </ShowAlertPopup>
        </PrivateRoute>

        <PrivateRoute exact path="/my-inventory/add-item">
          <ShowAlertPopup>
            <MyAddItem />
          </ShowAlertPopup>
        </PrivateRoute>

        <PrivateRoute exact path="/my-inventory">
          <ShowAlertPopup>
            <MyInventory />
          </ShowAlertPopup>
        </PrivateRoute>

        <PrivateRoute exact path="/my-settings">
          {/* <ShowAlertPopup> */}
          <MySettings />
          {/* </ShowAlertPopup> */}
        </PrivateRoute>

        <PrivateRoute exact path="/my-orders/order/:id">
          <ShowAlertPopup>
            <MyOrder />
          </ShowAlertPopup>
        </PrivateRoute>

        <PrivateRoute exact path="/my-orders">
          <ShowAlertPopup>
            <MyOrders />
          </ShowAlertPopup>
        </PrivateRoute>

        <Route exact path="/privacy_policy">
          <AgreePrivacyPolicy />
        </Route>

        <Route exact path="/terms_and_conditions">
          <AgreeTermsConditions />
        </Route>

        <Route>
          <P404 />
        </Route>
      </Switch>
    );
  }

  return <></>;
};

export default Router;
