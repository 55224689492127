import { combineReducers } from 'redux';

import * as reducers from './reducers';

export const combineReducer: any = combineReducers({
  filter: reducers.filterReducer,

  app: combineReducers({
    cart: reducers.app.cart.cartReducer,
    common: reducers.app.common.reducer,
    popup: reducers.app.popup.reducer,
  }),

  request: combineReducers({
    address: reducers.request.address.reducer,
    billing: reducers.request.billing.reducer,
    cart: reducers.request.cart.reducer,
    category: reducers.request.category.reducer,
    delivery: reducers.request.delivery.reducer,
    duration: reducers.request.duration.reducer,
    facebookBusiness: reducers.request.facebookBusiness.reducer,
    item: reducers.request.item.reducer,
    location: reducers.request.location.reducer,
    order: reducers.request.order.reducer,
    paypal: reducers.request.paypal.reducer,
    plan: reducers.request.plan.reducer,
    type: reducers.request.type.reducer,
    unit: reducers.request.unit.reducer,
    user: reducers.request.user.reducer,
  }),
});
