import * as util from 'utils';
import * as variables from 'variables';

export const categoryApi = {
  getAllCategoriesMe: (param: string) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/categories/me${param}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getAllCategoriesUser: (userName: string, param: string) => {
    return fetch(`https://${variables.api}.com/categories/user/${userName}${param}`, {
      method: 'GET',
    });
  },

  postCategory: (data: { name: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/categories/create`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  postCategories: (data: { name: string }[]) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/categories/create-multi`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  putCategoryID: (id: number, data: { name: string }) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/categories/update/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  putCategories: (data: { id: number; name: string }[]) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/categories/update-multi`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },

  deleteCategoryID: (id: number) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/categories/delete/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteCategories: (data: { id: number }[]) => {
    const token = util.cookie.get('token');

    return fetch(`https://${variables.api}.com/categories/delete-multi`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  },
};
