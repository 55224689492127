export const state = {
  getConnectPaypal: {
    data: (state: any) => state.request.paypal.getConnectPaypal.data,
    status: (state: any) => state.request.paypal.getConnectPaypal.status,
    error: (state: any) => state.request.paypal.getConnectPaypal.error,
  },

  getCheckSubscribePaypal: {
    data: (state: any) => state.request.paypal.getCheckSubscribePaypal.data,
    status: (state: any) => state.request.paypal.getCheckSubscribePaypal.status,
    error: (state: any) => state.request.paypal.getCheckSubscribePaypal.error,
  },

  getUrlPaypal: {
    data: (state: any) => state.request.paypal.getUrlPaypal.data,
    status: (state: any) => state.request.paypal.getUrlPaypal.status,
    error: (state: any) => state.request.paypal.getUrlPaypal.error,
  },
};
