export const initialState: any = {
  getAllCountries: {
    data: null,
    status: null,
    error: null,
  },
  getAllAddressesMe: {
    data: null,
    status: null,
    error: null,
  },

  getAddressID: {
    data: null,
    status: null,
    error: null,
  },

  postAddress: {
    data: null,
    status: null,
    error: null,
  },

  putAddressID: {
    data: null,
    status: null,
    error: null,
  },

  deleteAddressID: {
    data: null,
    status: null,
    error: null,
  },
};
