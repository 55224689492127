export const state = {
  mainNav: {
    isOpen: (state: any) => state.app.common.mainNav.isOpen,
  },

  mainSpinner: {
    isShow: (state: any) => state.app.common.mainSpinner.isShow,
  },

  token: (state: any) => state.app.common.token,
  subdomain: (state: any) => state.app.common.subdomain,
};
